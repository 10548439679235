<template>
  <div id="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Account</a></li>
            <li class="breadcrumb-item active" aria-current="page">Transactions</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-3">
      <div class="row gutters-3">
        <SideBar/>
        <div class="col mt-3 mt-md-0">
          <div class="card card-style1">
            <div class="card-body">
              <h4>My Transactions</h4>
              <hr>
              <div class="table-responsive" id="table-orders">
                <table class="table table-hover">
                  <thead class="thead-light">
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Payment Reference</th>
                    <th scope="col" class="text-right">Total</th>

                    <th scope="col" class="text-right">Amount Paid</th>
                    <th scope="col" class="text-right">Balance</th>

                    <th scope="col" class="text-center">Status</th>
                    <th scope="col" class="text-right">Payment Date</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="transaction in  transactions " :key="transaction.id">
                    <th scope="row">{{ transaction.order.reference_number }}</th>
                    <td>{{ transaction.reference_number }}</td>
                    <td class="text-right">{{
                        Number(transaction.total_amount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-right">{{
                        Number(transaction.total_paid).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-right">{{
                        Number(transaction.total_balance).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-center">
                       <span class="badge badge-danger" v-if="transaction.status.id==1"> {{
                           transaction.status.name
                         }}</span>
                      <span class="badge badge-warning" v-else-if="transaction.status.id==2"> {{
                          transaction.status.name
                        }}</span>
                      <span class="badge badge-success" v-else-if="transaction.status.id==3"> {{
                          transaction.status.name
                        }}</span>
                    </td>
                    <td class="text-right">{{ transaction.created_at }}</td>

                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

// import axios from "axios";
import OtherModal from '@/components/modal.vue'
import SideBar from '@/components/dashboard/sidemenu.vue'
import axios from "axios";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,
    SideBar,

  },
  computed: {},
  data() {
    return {
      transactions: [],

      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    this.getTransactions();
  },
  methods: {

    async getTransactions() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/transactions", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.transactions = response.data.data;
    }
  },


}

</script>


