<template>
  <div id="home">
    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ user.name }} Shopping Cart</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="row gutters-3">
        <div class="col-md-7 col-lg-8">
          <div class="card card-style1">
            <div class="card-body">
              <ul class="list-group list-group-flush list-group-sm">
                <li class="list-group-item px-0 d-flex align-items-center" v-if="cart == null">

                </li>
                <li class="list-group-item px-0 d-flex align-items-center" v-for="cartItem in cart.cart_items"
                    :key="cartItem.id">
                  <div class="media">
                    <a href="#">
                      <img :src="cartItem.product.default_image.image_path" alt="product" width="75">
                    </a>
                    <div class="media-body ml-3">
                      <a href="#" class="card-link text-secondary">{{ cartItem.product.name }}</a>
                      <div class="price"><span v-if="cartItem.product.showPrice=='1'">{{
                          cartItem.product.currency.symbol
                        }}{{
                          Number(cartItem.product.price).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })

                        }}</span>
                        <span v-else>ON REQUEST</span>
                        x Qty {{ cartItem.qty }}
                      </div>
                      <div class="font-size-sm"><span class="text-muted">Model / Catalogue #:</span>
                        {{ cartItem.product.model }}
                      </div>
                    </div>
                  </div>
                  <div class="custom-spinner custom-spinner-vertical ml-auto">
                    <span v-if="cartItem.product.showPrice=='1'">{{
                        cartItem.product.currency.symbol
                      }}{{

                        Number(cartItem.qty * cartItem.product.price).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })


                      }}</span>
                    <span v-else>REQUEST PRICE</span>

                  </div>
                  <div class="dropdown ml-sm-5">
                    <button class="dropdown-item has-icon text-danger" type="button" @click="deleteItem(cartItem.id)"><i
                        class="material-icons mr-2">close</i>
                      Remove item
                    </button>

                    <div class="dropdown-menu dropdown-menu-right font-size-sm">

                    </div>
                  </div>
                </li>


              </ul>
              <div class="text-right mt-3">
                <button data-toggle="tooltip" title="Remove all items from cart"
                        class="btn btn-sm btn-faded-danger has-icon" @click="deleteAll(cart.id)"><i
                    class="material-icons">close</i> Remove all
                </button>
              </div>
              <hr>

              <div class="btn-group-toggle" data-toggle="buttons">
                <div class="row">
                  <div class="col-6">
                    <label><input type="radio" v-model="deliveryOption" name="radio"
                                  @click="showDelivery(1)"><span
                        class="auth-label w-form-label" for="radio">Create New Shipping Address</span></label>

                  </div>

                  <div class="col-6">
                    <label><input type="radio" v-model="deliveryOption" name="radio"
                                  @click="showDelivery(2)"><span
                        class="auth-label w-form-label" for="radio">Existing Delivery Address</span></label>
                  </div>
                </div>
              </div>
              <div class="card-body" v-show="existing">
=                <select class="form-control" v-model="selectDelivery" @change="getDeliveryPrice">
                  <option value="">Select</option>
                  <option v-for="address in myAddress" :key="address.id" :value="address.id">
                    {{ address.name }} {{ address.address }}
                  </option>
                </select>
              </div>
              <div class="card-body" v-show="newForm">
                <h4>Shipping Address</h4>
                <hr>
                <form class="form-style-1">
                  <div class="form-row">

                    <div class="form-group col-6">
                      <label>Address</label>
                      <textarea type="text" class="form-control" id="billingAddress" v-model="address"></textarea>
                    </div>
                    <div class="form-group col-6">
                      <label>In Care of Off (Name)</label>
                      <input type="text" class="form-control" id="name" v-model="name">
                    </div>
                    <div class="form-group col-4 col-sm-4">
                      <label>State</label>
                      <select class="form-control" v-model="stateId" @change="getLocation(stateId)">
                        <option>Choose State</option>
                        <option v-for="state in mystates" :key="state.id" :value="state.id">
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-4 col-sm-4">
                      <label>Location</label>
                      <select class="form-control" v-model="location" @change="getDeliveryPrice">
                        <option>Choose Location</option>
                        <option v-for="item in locations" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-4 col-sm-4">
                      <label for="billingCity">Phone</label>
                      <input type="text" class="form-control" id="billingCity" v-model="phone">
                    </div>

                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-5 col-lg-4 mt-1 mt-md-0">
          <div class="card card-style1">
            <div class="card-body" v-if="cart.request_quote==1">

              <div class="mt-3 font-condensed">

                <div class="d-flex">

                  <small>Select Preferred Currency for Invoice</small>
                  <select class="form-control" v-model="mycurrency">
                    <option> Select</option>

                    <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                      {{ currency.name }} {{ currency.symbol }}
                    </option>
                  </select>
                </div>
                <hr>
                <div class="d-flex">
                  <div class="text-muted">Sub Total</div>
                  <div class="ml-auto font-weight-bold">0.00</div>
                </div>
                <div class="d-flex">
                  <div class="text-muted">Discount</div>
                  <div class="ml-auto font-weight-bold">0.00</div>
                </div>
                <hr class="my-1">
                <div class="d-flex">
                  <div class="text-muted">Shipping Cost</div>
                  <div class="ml-auto font-weight-bold"> 0.00</div>
                </div>
                <hr>
                <div class="d-flex">
                  <div class="text-muted">Grand Total</div>
                  <div class="ml-auto font-weight-bold text-primary h4 mb-0">0.00</div>
                </div>
                <hr>
              </div>
              <button class="btn btn-lg btn-block btn-primary" @click="requestQuote">REQUEST QUOTE</button>

            </div>
            <div class="card-body" v-else>
              <div class="mt-3 font-condensed">
                <div class="d-flex">
                  <div class="text-muted">Sub Total</div>
                  <div class="ml-auto font-weight-bold">₦{{
                      Number(sub_total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </div>
                </div>
                <hr class="my-1">
                <div class="d-flex">
                  <div class="text-muted">Shipping Cost</div>
                  <div class="ml-auto font-weight-bold">₦{{
                      Number(deliveryFee).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </div>
                </div>
                <hr>
                <div class="d-flex">
                  <div class="text-muted">Grand Total</div>
                  <div class="ml-auto font-weight-bold text-primary h4 mb-0">₦{{
                      Number(total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </div>
                </div>
                <hr>
              </div>
              <button class="btn btn-lg btn-block btn-primary" @click="proceedToCheckout">PROCEED TO CHECKOUT</button>

            </div>
          </div>
        </div>


      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

import axios from "axios";
import OtherModal from '@/components/modal.vue'


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,

  },
  computed: {
    totalItem: function () {
      let sum = 0;

      this.cart.cart_items.forEach(function (item) {
        sum += (parseFloat(item.product.price) * parseFloat(item.qty));
      });
      return sum;
    }
  },
  data() {
    return {
      cart: [],
      discount: 0,
      shipping: 0,
      currencies: [],
      exchanges: {},
      mycurrency: '',
      mystates: [],
      price: '',
      locations: [],
      myAddress: [],
      radio: '',

      newForm: false,
      existing: false,
      deliveryOption: '',
      selectDelivery: '',

      address: '',
      name: '',
      stateId: '',
      location: '',
      phone: '',
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null,

      deliveryFee: 0,
      sub_total: 0,
      total: 0,

    }
  },
  created() {
    this.newBies();
    this.getCarts();
    this.getCurrency();
    this.getAddress();
  },
  methods: {

    async getAddress() {
      const local_token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem("user"));

      const response = await axios.get(this.$store.state.url + "/deliveryAddress/" + user.id, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.myAddress = response.data;

    },
    async getCarts() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/cart", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.cart = response.data.data;

    },
    async getCurrency() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/currency", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.currencies = response.data.data;
    },
    async deleteItem(value) {
      const local_token = localStorage.getItem('token');
      const response = await axios.delete(this.$store.state.url + "/cart/" + value, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        },
      });
      if (response.status == 201) {
        this.$toast.open({
          message: 'Item deleted successfully',
          type: "success",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.getCarts();
      }
    },
    async deleteAll(value) {
      const local_token = localStorage.getItem('token');
      const response = await axios.delete(this.$store.state.url + "/cart/empty/" + value, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        },
      });
      if (response.status == 201) {
        this.$toast.open({
          message: 'Item deleted successfully',
          type: "success",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.getCarts();
      }
    },

    showDelivery(value) {
      console.log(value);
      if (value === 1) {
        this.existing = false;
        this.newForm = true;

      } else if (value === 2) {
        this.existing = true;
        this.newForm = false;
      }
    },
    async requestQuote() {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {

        const local_token = localStorage.getItem('token');
        const formData = {
          "cart": this.cart.id,
          "address": this.address,
          "name": this.name,
          "state": this.stateId,
          "telephone": this.phone,
          "currency": this.mycurrency,
          "user": user.id,
          "deliveryOption": this.deliveryOption,
          "selectDelivery": this.selectDelivery,
          "locationId": this.location,
        }
        try {
          const response = await axios.post(this.$store.state.url + "/request-quote", formData, {
            headers: {
              'Authorization': `Bearer ${local_token}`
            }
          });
          if (response.status == 201) {

            this.$toast.open({
              message: "Request for quote submitted successfully",
              type: "success",
              duration: 5000,
              dismissible: true,
              position: 'top'
            });
            this.$router.push("/order");

          }

        } catch (error) {
          this.$toast.open({
            message: error.response.data.message,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
        }


      } else {
        this.$router.push("/login");

      }
    },
    async newBies() {
      const response = await axios.get(this.$store.state.url + "/states");
      this.mystates = response.data;
    },

    async getLocation(value) {
      const response = await axios.get(this.$store.state.url + "/locations/" + value)
      this.locations = response.data.data;
    },
    async getDeliveryPrice() {
      const local_token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem("user"));

      const formData = {
        "cart": this.cart.id,
        "currency": this.mycurrency,
        "user": user,
        "selectDelivery": this.selectDelivery,
        "locationId": this.location,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/deliveryFee", formData, {
          headers: {
            'Authorization': `Bearer ${local_token}`
          }
        });
        if (response.status == 201) {
          this.deliveryFee = response.data.deliveryFee;
          this.sub_total = response.data.total;
          this.total = this.deliveryFee + this.sub_total;
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        });
      }
    },

    async proceedToCheckout() {
      const user = JSON.parse(localStorage.getItem("user"));

      if (this.radio == null) {
        this.$toast.open({
          message: "Select a delivery address option to proceed",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
      } else {


        if (user) {


          const local_token = localStorage.getItem('token');
          const formData = {
            "cart": this.cart.id,
            "address": this.address,
            "name": this.name,
            "state": this.stateId,
            "telephone": this.phone,
            "currency": this.mycurrency,
            "user": user,
            "deliveryOption": this.deliveryOption,
            "selectDelivery": this.selectDelivery,
            "locationId": this.location,
            "total": this.sub_total,
            "deliveryFee": this.deliveryFee,
          }
          try {
            const response = await axios.post(this.$store.state.url + "/place-order", formData, {
              headers: {
                'Authorization': `Bearer ${local_token}`
              }
            });
            if (response.status == 201) {

              this.$toast.open({
                message: "Kindly complete your order by making payment",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: 'top'
              });
              this.$router.push("/order-details/" + response.data.order);

            }

          } catch (error) {
            this.$toast.open({
              message: error.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: 'top-right'
            })
          }


        } else {
          this.$router.push("/login");

        }

      }
    }
  },

}

</script>


