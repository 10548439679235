<template>
  <div class="home">
    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">{{ product.category.name }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ product.name }}</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="card card-style1">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="swiper-gallery">
                <div class="swiper-gallery-carousel">
                  <div class="swiper-container" id="mainSlider">
                    <div class="swiper-wrapper">
                      <img :src="product.default_image.image_path" :data-large="product.default_image.image_path"
                           alt="Product" class="swiper-slide">

                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-button-prev autohide"><i class="material-icons">chevron_left</i></div>
                    <div class="swiper-button-next autohide"><i class="material-icons">chevron_right</i></div>
                    <button class="btn btn-icon btn-text-danger rounded-circle btn-wishlist atw-demo"
                            data-toggle="button" title="Add to wishlist" @click="saveLater(product.id)"></button>
                  </div>
                </div>
                <div class="swiper-gallery-thumb">
                  <div class="swiper-container" id="thumbSlider">
                    <div class="swiper-wrapper">
                      <div v-for="image in product.product_images" :key="image.id" :src="image.image_path">
                      <img
                           alt="product.name" class="swiper-slide img-thumbnail"  style="height:50px;">
                      </div>
                    </div>
                    <div class="swiper-button-prev swiper-button-prev-sm vertical"><i class="material-icons">keyboard_arrow_up</i>
                    </div>
                    <div class="swiper-button-next swiper-button-next-sm vertical"><i class="material-icons">keyboard_arrow_down</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 pt-3 pt-md-0">
              <h4 class="card-title font-weight-normal mb-3">{{ product.name }}</h4>
              <h3 class="price mb-3">
                <span v-if="product.showPrice == 1">{{
                    product.currency.symbol
                  }}{{ Number(product.price).toLocaleString() }}</span>
                <span v-if="product.showPrice == 2">REQUEST PRICE</span>

              </h3>
              <form>
                <div class="form-group">
                  <label class="font-weight-bold d-block">Model / Catalog #</label>
                  {{ product.model }}
                </div>
                <div class="form-group">
                  <label class="font-weight-bold d-block">Brand</label>
                  {{ product.brand.name }}
                </div>
                <div class="form-row">
                  <div class="form-group col-6 col-sm-4 col-md-5 col-lg-4">
                    <label class="d-block font-weight-bold">Quantity</label>
                    <div class="custom-spinner">
                      <!--                      <div class="btn btn-icon rounded-circle btn-faded-primary down" style="cursor: pointer" @click="decrement">-</div>-->
                      <!--                      <div class="quantity-slot">{{ qty }}</div>-->
                      <!--                      <div class="quantity-slot btn btn-icon rounded-circle btn-faded-primary up" style="cursor: pointer" @click="increment">+</div>-->

                      <button class="btn btn-icon rounded-circle btn-faded-primary down" type="button"
                              @click="decrement"><i class="material-icons">remove</i></button>
                      <input type="number" class="form-control" v-model="qty">

                      <button class="btn btn-icon rounded-circle btn-faded-primary up" type="button" @click="increment">
                        <i class="material-icons">add</i></button>
                      <input type="hidden" class="form-control" v-model="currency" value="product.currency.id">

                    </div>
                  </div>
                  <div class="form-group col-6 col-sm-8 col-md-7 col-lg-8 d-flex align-items-end">
                    <button class="btn rounded-pill btn-primary btn-block atc-demo" type="button"
                            data-title="product.name" @click="addCart">Add to Cart
                    </button>
                    <button class="btn rounded-pill btn-warning btn-block atc-demo" type="button"
                            data-title="product.name" @click="saveLater(product.id)">Add to Wish List
                    </button>
                  </div>
                </div>
              </form>
              <hr>

            </div>
          </div>
        </div>
        <nav class="nav nav-tabs justify-content-center border-bottom mt-3">
          <a class="nav-item nav-link has-icon active" href="#description-content" data-toggle="tab"><i
              class="material-icons mr-1">list</i>Description</a>
          <a class="nav-item nav-link has-icon" href="#specification-content" data-toggle="tab"><i
              class="material-icons mr-1">list</i>Specification</a>

          <!--          <a class="nav-item nav-link has-icon" href="#review-content" data-toggle="tab"><i class="material-icons mr-1">star_border</i>Reviews-->
          <!--            (2)</a>-->
        </nav>
        <div class="tab-content pt-4">
          <div class="tab-pane fade show active" id="description-content" v-html="product.description">


          </div>

          <div class="tab-pane fade show" id="specification-content" v-html="product.specification"></div>

          <div class="tab-pane fade " id="review-content">
            <div class="row gutters-3 justify-content-center">
              <div class="col-11 col-sm-6 col-md-4 col-lg-3">
                <h5 class="d-flex align-items-center font-condensed">
                  Overall Rating: 4.5
                  <span class="rating ml-1" data-value="4.5"></span>
                </h5>
                <div class="list-group">
                  <a href="javascript:void(0)"
                     class="list-group-item list-group-item-action text-muted d-flex align-items-center justify-content-between"><span
                      class="rating" data-value="5"></span> <span>21</span></a>
                  <a href="javascript:void(0)"
                     class="list-group-item list-group-item-action text-muted d-flex align-items-center justify-content-between"><span
                      class="rating" data-value="4"></span> <span>5</span></a>
                  <a href="javascript:void(0)"
                     class="list-group-item list-group-item-action text-muted d-flex align-items-center justify-content-between"><span
                      class="rating" data-value="3"></span> <span>2</span></a>
                  <a href="javascript:void(0)"
                     class="list-group-item list-group-item-action text-muted d-flex align-items-center justify-content-between"><span
                      class="rating" data-value="2"></span> <span>1</span></a>
                  <a href="javascript:void(0)"
                     class="list-group-item list-group-item-action text-muted d-flex align-items-center justify-content-between"><span
                      class="rating" data-value="0"></span></a>
                </div>
                <div class="text-center mt-1">
                  <button class="btn btn-primary btn-block" data-toggle="modal" data-target="#reviewModal">Write a
                    review
                  </button>
                </div>
              </div>
              <div class="col-md-8 col-lg-9 mt-5 mt-md-0">
                <div class="media">
                  <a href="javascript:void(0)"><img src="./img/user/user1.svg" alt="User" width="45" height="45"
                                                    class="rounded-circle"></a>
                  <div class="media-body ml-2 ml-sm-3">
                    <h6>Terrific Shirt, Exactly What I Wanted.</h6>
                    <p class="d-flex flex-wrap align-items-center">
                      <span class="rating mr-2" data-value="5"></span>
                      <a href="javascript:void(0)" class="mr-2">Alan Lewis</a>
                      <span class="text-muted font-size-sm">3 days ago</span>
                    </p>
                    <p>The Hanes sweatshirt was precisely what I wanted except for the color which is better than what I
                      thought I was getting. This order went perfectly, so much so that another two are being shipped to
                      me right now. It is way too early to say anything about durability; but otherwise, there is no way
                      this order could have gone any better than it did. If you are interested in a pretty traditional
                      long-sleeve sweatshirt, you would do well to give this one serious consideration.</p>
                    <hr>
                  </div>
                </div>
                <div class="media">
                  <a href="javascript:void(0)"><img src="./img/user/user2.svg" alt="User" width="45" height="45"
                                                    class="rounded-circle"></a>
                  <div class="media-body ml-2 ml-sm-3">
                    <h6>Very comfortable.</h6>
                    <p class="d-flex flex-wrap align-items-center">
                      <span class="rating mr-2" data-value="4"></span>
                      <a href="javascript:void(0)" class="mr-2">Amy</a>
                      <span class="text-muted font-size-sm">5 days ago</span>
                    </p>
                    <p>Fits slightly larger than expected, but gets longer, not just wider. (For reference, I am female,
                      5'8'', 170lbs, and this sweatshirt fits around my 36J chest with room to spare, coming to rest at
                      about crotch level)</p>
                    <hr>
                  </div>
                </div>
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled"><span class="page-link has-icon"><i
                      class="material-icons">chevron_left</i></span></li>
                  <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                  <li class="page-item active"><span class="page-link">2</span></li>
                  <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                  <li class="page-item"><a class="page-link has-icon" href="javascript:void(0)"><i
                      class="material-icons">chevron_right</i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- RELATED PRODUCTS -->
      <div v-if="product.bought.length">
        <h4 class="text-center mt-4">Frequently Bought Together</h4>
        <div class="d-grid grid-template-col-2 grid-template-col-md-4 grid-gap-1 grid-gap-sm-3 my-3">
          <div class="card card-product card-style1" v-for="item in product.bought" :key="item.id">
            <div class="card-body">
              <button class="btn btn-icon btn-text-danger rounded-circle btn-wishlist atw-demo" data-toggle="button"
                      title="Add to wishlist"></button>
              <router-link :to="{ name: 'ProductPage', params: { id: item.id }}"><img class="card-img-top"
                                                                                      :src="item.product.default_image.image_path"
                                                                                      alt="Card image cap">
              </router-link>
              <router-link :to="{ name: 'ProductPage', params: { id: item.id }}" class="card-title card-link">
                {{ item.product.name }}
              </router-link>
              <span class="badge badge-success">{{ item.product.category.name }}</span>

              <div class="price" v-if="item.product.showPrice == 1">
                <span class="h4">{{ item.product.currency.symbol }}{{
                    Number(item.product.price).toLocaleString()
                  }}</span>
              </div>
              <div class="price" v-if="item.product.showPrice == 2">
                <span class="h4">ON REQUEST</span>
              </div>
            </div>

            <div class="card-footer" v-if="item.product.showPrice == 1">
              <button class="btn btn-sm rounded-pill btn-faded-primary btn-block atc-demo">Add to Cart</button>
            </div>

            <div class="card-footer" v-if="item.product.showPrice == 2">
              <button class="btn btn-sm rounded-pill btn-faded-danger btn-block atc-demo">Request Quote</button>
            </div>
          </div>


        </div>

      </div>
      <!-- /RELATED PRODUCTS -->


      <!-- RELATED PRODUCTS -->
      <div v-else>
        <h4 class="text-center mt-4">RELATED PRODUCTS</h4>
        <div class="d-grid grid-template-col-2 grid-template-col-md-4 grid-gap-1 grid-gap-sm-3 my-3">
          <div class="card card-product card-style1" v-for="item in product.category.products" :key="item">
            <div class="card-body">
              <button class="btn btn-icon btn-text-danger rounded-circle btn-wishlist atw-demo" data-toggle="button"
                      title="Add to wishlist"></button>
              <router-link :to="{ name: 'ProductPage', params: { id: item.id }}"><img class="card-img-top"
                                                                                      :src="item.default_image.image_path"
                                                                                      alt="Card image cap">
              </router-link>
              <router-link :to="{ name: 'ProductPage', params: { id: item.id }}" class="card-title card-link">
                {{ item.name }}
              </router-link>
              <span class="badge badge-success">{{ item.product_class.name }}</span>
              <div class="price" v-if="item.showPrice == 1">
                <span class="h4">{{ item.currency.symbol }}{{ Number(item.price).toLocaleString() }}</span>
              </div>
              <div class="price" v-if="item.showPrice == 2">
                <span class="h4">ON REQUEST</span>
              </div>

            </div>
            <div class="card-footer" v-if="item.showPrice == 1">
              <button class="btn btn-sm rounded-pill btn-faded-primary btn-block atc-demo">Add to Cart</button>
            </div>

            <div class="card-footer" v-if="item.showPrice == 2">
              <button class="btn btn-sm rounded-pill btn-faded-danger btn-block atc-demo">Request Quote</button>
            </div>
          </div>


        </div>

      </div>
      <!-- /RELATED PRODUCTS -->


    </div>
    <OtherModal/>
  </div>

</template>

<script>

import axios from "axios";
import OtherModal from '@/components/modal.vue'


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,

  },
  data() {
    return {
      product: '',
      qty: 0,
      currency: '',
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  created() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      const response = await axios.get(this.$store.state.url + "/products/" + this.$route.params.id);
      this.product = response.data.data;
      this.currency = this.product.currency.id
      setTimeout(() => {
        window.App.init();
        window.App.brandSlider('#brandSlider');
      }, 0);
    },

    // add to cart
    async addCart() {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        if (this.qty > 0) {

          const local_token = localStorage.getItem('token');
          const formData = {
            "product": this.product.id,
            "qty": this.qty,
            "user": user.id,
            "currency": this.currency,
          }
          try {
            const response = await axios.post(this.$store.state.url + "/cart", formData, {
              headers: {
                'Authorization': `Bearer ${local_token}`
              }
            });

            if (response.status == 201) {
              // this.$toast.success('Product added to cart successfully.', {
              //   // override the global option
              //   position: 'top'
              // })
              this.$toast.open({
                message: "Item added to cart successfully",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: 'top'
              });
              location.reload();

            }

          } catch (error) {
            this.$toast.open({
              message: error.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: 'top-right'
            })
          }

        } else {
          this.$toast.open({
            message: "Quantity cannot be zero",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: 'top'
          })
        }
      } else {
        this.$router.push("/login");

      }
    },
    increment() {
      this.qty++;
    },
    decrement() {
      if (this.qty <= 0) {
        return false;
      } else {
        this.qty--;
      }
    },

    async saveLater(value) {
      const local_token = localStorage.getItem('token');
      const formData = {
        "product": value,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/wishlist", formData, {
          headers: {
            'Authorization': `Bearer ${local_token}`
          }
        });
        if (response.status == 201) {
          this.$toast.open({
            message: 'Item added to Wish list successfully',
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
          location.reload();
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
      }

    },


  }

}

</script>


