<template>
  <div class="home">


    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Reset Password</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="card card-style1">
        <div class="card-body">

          <div class="row">
            <div class="col-md-4">

            </div>
            <div class="col-md-4">

              <!-- Contact Us Form -->
              <div>
                <h3>Reset Password</h3>
                <p>Reset your password</p>
                <form class="mt-3 form-style-1">
                  <div class="form-group">
                <span class="input-icon">
                  <i class="material-icons">email</i>
                  <input type="email" class="form-control" v-model="email" placeholder="Enter your email">
                </span>
                  </div>

                  <div class="form-group">
                <span class="input-icon">
                  <i class="material-icons">password</i>
                  <input type="password" class="form-control" v-model="password" placeholder="New Password">
                </span>
                  </div>
                  <div class="form-group">
                <span class="input-icon">
                  <i class="material-icons">password</i>
                  <input type="password" class="form-control" v-model="confirm_password" placeholder="Confirm Password">
                </span>
                  </div>

                  <button type="button" class="btn btn-primary" @click="resetPassword">Reset Password</button>
                </form>
              </div>
              <!-- /Contact Us Form -->

            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>

      <OtherModal/>
    </div>

  </div>


</template>

<script>
import {mapGetters} from "vuex"

import OtherModal from '@/components/modal.vue'
import axios from "axios";

export default {
  components: {
    OtherModal,
  },
  data() {
    return {
      email: '',
      password: '',
      confirm_password: '',
    }
  },
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {
    this.$store.dispatch("getCompany");

  },
  methods: {
    async resetPassword() {
      const formData = {
        "email": this.email,
        "password": this.password,
        "password_confirmation": this.confirm_password,
        "token": this.$route.query.token,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/reset-password", formData);

        if (response.status == 200) {
          this.$toast.open({
            message: "Password reset successful",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorName = error.response.data.errors.name;

      }
    }

  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


