<template>

  <div class="col-md-4 col-lg-3">
    <div class="card card-style1">
      <div class="card-body text-center">
        <img src="/img//user/user.svg" width="100" height="100" alt="User" class="rounded-circle mb-3">
        <h5 class="mb-0">{{ user.name }}</h5>
        <small class="text-muted">Joined {{ user.created_at }}</small>

      </div>
      <div class="list-group list-group-flush">
        <router-link :to="{ name: 'Profile'}" class="list-group-item list-group-item-action d-flex">
          <i class="material-icons mr-3">person</i> Profile
        </router-link>
        <router-link :to="{ name: 'Order'}" class="list-group-item list-group-item-action d-flex">
          <i class="material-icons mr-3">shopping_cart</i> Orders <span
            class="badge badge-light badge-pill ml-auto">{{ countOrder }}</span>
        </router-link>
        <router-link :to="{ name: 'Transactions'}" class="list-group-item list-group-item-action d-flex">
          <i class="material-icons mr-3">assignment</i> Transactions
        </router-link>
        <router-link :to="{ name: 'Wishlist'}" class="list-group-item list-group-item-action d-flex align-items-center">
          <i class="material-icons mr-3">favorite_border</i> Wishlist
          <span class="badge badge-light badge-pill ml-auto">{{ countWish}}</span>
        </router-link>
        <router-link :to="{ name: 'ShippingAddress'}" class="list-group-item list-group-item-action d-flex">
          <i class="material-icons mr-3">location_on</i>Shipping Address
        </router-link>
        <a href="account-signin.html" class="list-group-item list-group-item-action text-danger d-flex">
          <i class="material-icons mr-3">exit_to_app</i> Sign Out
        </a>
      </div>
    </div>
  </div>
</template>


<script>

import axios from "axios";

export default {
  components: {},
  computed: {},
  data() {
    return {
      wishlists: [],
      countOrder: 0,
      countWish: 0,
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  created() {
    this.getOrders();
    this.getWishlist();
  },
  methods: {
    async getOrders() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/order", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.orders = response.data.data;
      this.countOrder = this.orders.length;
    },

    async getWishlist() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/wishlist", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.wishlists = response.data.data;
      this.countWish = this.wishlists.length;
    },

  }

}

</script>


