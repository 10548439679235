<template>
  <div id="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Account</a></li>
            <li class="breadcrumb-item active" aria-current="page">Shipping Addresses</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-3">
      <div class="row gutters-3">
        <SideBar/>

        <div class="col mt-3 mt-md-0">
          <div class="card card-style1">
            <div class="card-body">
              <h4>Shipping Address</h4>
              <hr>
              <table class="table table-hover">
                <thead class="thead-light">
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col" class="text-right">Address</th>


                  <th scope="col"></th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="address in  myAddresses " :key="address.id">
                  <td>{{ snn++ }}</td>
                  <th scope="row">{{ address.name }}</th>
                  <th scope="row">{{ address.address }} {{ address.state.name}}</th>
                  <th scope="row"><span class="text-danger">x</span></th>


                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

// import axios from "axios";
import OtherModal from '@/components/modal.vue'
import SideBar from '@/components/dashboard/sidemenu.vue'
import axios from "axios";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,
    SideBar,

  },
  computed: {},
  data() {
    return {
      full_name: '',
      company_name: '',
      telephone: '',
      email: '',
      password: '',
      confirm_password: '',
      states: [],
      myAddresses: [],
      snn: 1,

      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.populateProfile();
  },
  created() {
    this.getAddress();
  },
  methods: {
    populateProfile() {
      const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
      this.full_name = user.name;
      this.company_name = user.company_name;
      this.telephone = user.phone;
      this.email = user.email;
    },

    async getState() {
      const response = await axios.get(this.$store.state.url + "/states");
      this.states = response.data;
    },
    async getAddress() {
      const local_token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem("user"));

      const response = await axios.get(this.$store.state.url + "/deliveryAddress/" + user.id, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.myAddresses = response.data;

    },


  }

}

</script>


