<template>
  <div id="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Account</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-3">
      <div class="row gutters-3">
        <SideBar/>
        <div class="col mt-3 mt-md-0">
          <div class="card card-style1">
            <div class="card-body">
              <h4>ORD{{ order.reference_number }}</h4>
              <hr>

              <nav class="steps" v-if="order.status.id ==1">
                <a href="javascript:void(0)" data-step="1" class="done">Processing</a>
                <a href="javascript:void(0)" data-step="2" >Payment Confirmed</a>
                <a href="javascript:void(0)" data-step="3">In Transit</a>
                <a href="javascript:void(0)" data-step="4">Delivered</a>
              </nav>
              <nav class="steps" v-if="order.status.id ==2">
                <a href="javascript:void(0)" data-step="1" class="done">Processing</a>
                <a href="javascript:void(0)" data-step="2" class="done">Payment Confirmed</a>
                <a href="javascript:void(0)" data-step="3">In Transit</a>
                <a href="javascript:void(0)" data-step="4">Delivered</a>
              </nav>
              <nav class="steps" v-if="order.status.id ==3">
                <a href="javascript:void(0)" data-step="1" class="done">Processing</a>
                <a href="javascript:void(0)" data-step="2" class="done">Payment Confirmed</a>
                <a href="javascript:void(0)" data-step="3" class="done">In Transit</a>
                <a href="javascript:void(0)" data-step="4">Delivered</a>
              </nav>
              <nav class="steps" v-if="order.status.id ==4">
                <a href="javascript:void(0)" data-step="1" class="done">Processing</a>
                <a href="javascript:void(0)" data-step="2" class="done">Payment Confirmed</a>
                <a href="javascript:void(0)" data-step="3" class="done">In Transit</a>
                <a href="javascript:void(0)" data-step="4" class="done">Delivered</a>
              </nav>

              <ul class="list-group list-group-flush list-group-sm mt-4">
                <li class="list-group-item px-0" v-for="cartItem in order.cart.cart_items" :key="cartItem.id">
                  <div class="media">
                    <a href="shop-single.html">
                      <img :src="cartItem.product.default_image.image_path" alt="product" width="75">
                    </a>
                    <div class="media-body ml-3">
                      <a href="shop-single.html" class="card-link text-secondary">{{ cartItem.product.name }}</a>
                      <div class="font-size-sm"><span class="text-muted">Model / Catelogue</span>
                        {{ cartItem.product.model }}
                      </div>
                      <div class="price">
                        <span class="text-success">{{ order.currency.symbol }}{{

                            Number(cartItem.price).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })

                          }}</span>

                        <span class="ml-3 text-secondary font-weight-normal">x {{ cartItem.qty }} </span>
                        <span class="ml-3 text-success">= {{ order.currency.symbol }}{{
                            Number(cartItem.qty * cartItem.price).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })

                          }}</span>

                      </div>
                    </div>
                  </div>
                </li>

              </ul>

              <div class="mt-3 font-condensed">
                <div class="d-flex">
                  <div class="text-muted">Sub Total</div>
                  <div class="ml-auto font-weight-bold">{{ order.currency.symbol }}{{
                      Number(order.total_amount).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </div>
                </div>

                <hr class="my-1">

                <div class="d-flex">
                  <div class="text-muted">Shipping Cost</div>
                  <div class="ml-auto font-weight-bold">{{ order.currency.symbol }}{{
                      Number(order.delivery_fee).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </div>
                </div>
                <hr>
                <div class="d-flex">
                  <div class="text-muted">Order Total</div>
                  <div class="ml-auto font-weight-bold text-primary h4 mb-0">
                    {{ order.currency.symbol }}{{
                      Number(total).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })

                    }}

                  </div>
                </div>
                <hr>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <h5><u>Shipping Address :</u></h5>
                  <strong>{{ order.delivery.name }}</strong><br/>
                  {{ user.email }}<br/>
                  {{ order.delivery.phone }}<br/>
                  {{ order.delivery.address }}<br/>

                  {{ order.delivery.state.name }}<br/>
                </div>
                <div class="col-lg-6 mt-4 mt-lg-0">
                  <div v-if="order.order_status_id==1 && order.order_type==1">
                    No Payment Record Received Yet
                  </div>
                  <div v-if="order.order_status_id==1">
                    <div v-if="order.order_type==2">
                      <h5><u>Payment Method :</u></h5>


                      <div class="text-muted">
                        <paystack

                            :amount="total + '00'"

                            :email="user.email"

                            :paystackkey="$store.getters.getPayStack['PUBLIC_KEY']"

                            :reference="reference"

                            :callback="callback"

                            :close="close"
                            class="btn-block btn-success">Pay with Paystack
                        </paystack>


                      </div>

                    </div>
                  </div>
                  <div>
                    <h5>Order Status: <span class="badge badge-danger" v-if="order.status.id==1"> {{
                        order.status.name
                      }}</span>
                      <span class="badge badge-dark" v-else-if="order.status.id==2"> {{ order.status.name }}</span>
                      <span class="badge badge-warning" v-else-if="order.status.id==3"> {{ order.status.name }}</span>
                      <span class="badge badge-success" v-else-if="order.status.id==4"> {{ order.status.name }}</span>

                    </h5>

                  </div>


                </div>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <a href="account-orders.html" class="btn btn-outline-primary btn-sm has-icon"><i class="material-icons">chevron_left</i>
                  Back</a>
                <button type="button" class="btn btn-primary btn-sm has-icon"><i class="material-icons mr-2">print</i>
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

// import axios from "axios";
import OtherModal from '@/components/modal.vue'
import SideBar from '@/components/dashboard/sidemenu.vue'
import axios from "axios";
import paystack from 'vue-paystack';


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,
    SideBar,
    paystack,

  },
  computed: {
    reference() {
      let text = "";

      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },

  },
  data() {
    return {
      order: '',
      total: "",
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    this.getOrderItem();
    this.$store.getters.getPayStack;
  },
  methods: {
    async getOrderItem() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/order/" + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.order = response.data.data;
      this.total = parseInt(this.order.total_amount) + parseInt(this.order.delivery_fee);
    },

    callback: function (response) {
      if (response.status == "success") {
        const user = JSON.parse(localStorage.getItem("user"));

        if (user) {

          const local_token = localStorage.getItem('token');
          const formData = {
            "order": this.order.id,
            "status": response.status,
            "reference": response.reference,
            "response": response.response,
          }
          try {
            const response = axios.post(this.$store.state.url + "/payments/paystack-reference", formData, {
              headers: {
                'Authorization': `Bearer ${local_token}`
              }
            });
            if (response.status == 201) {

              this.$toast.open({
                message: "Your payment was successfully received, an e-receipt has been sent to your mail.",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: 'top'
              });
              this.$router.push("/order");

            }

          } catch (error) {
            this.$toast.open({
              message: error.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: 'top-right'
            })
          }


        } else {
          this.$router.push("/login");

        }
      }
    },
    close: function () {
      console.log("Payment closed")
    }


  }

}

</script>


