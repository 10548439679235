<template>
  <div class="home">


    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Pages</a></li>
            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="card card-style1">
        <div class="card-body">
          <!-- <div class="row"> -->
          <div class="d-grid grid-template-col-md-2 grid-gap-5">
            <!-- Map -->
            <div class="embed-responsive embed-map">
              <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=119,%20Commercial%20Avenue,%20Oyadiran%20Estate,%20Sabo,%20Yaba,%20Lagos%20State&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>

            <!-- Contact Us Form -->
            <div>
              <h3>Contact Us</h3>
              <form class="mt-3 form-style-1">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <div class="media">
                      <span><i class="fa fa-map-marker fa-fw text-info"></i></span>
                      <div class="media-body ml-1">
                        <div>{{ $store.getters.getCompany.address }}</div>

                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="media mb-3 mb-md-0">
                      <span><i class="fa fa-phone fa-fw text-info"></i></span>
                      <div class="media-body ml-1">Telephone: {{ $store.getters.getCompany.phone }},<br> WhatsApp: {{ $store.getters.getCompany.telephone }}</div>
                    </div>
                    <div class="media">
                      <span><i class="fa fa-envelope fa-fw text-info"></i></span>
                      <div class="media-body ml-1">{{ $store.getters.getCompany.email }}</div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                <span class="input-icon">
                  <i class="material-icons">person</i>
                  <input type="text" v-model="name" class="form-control" placeholder="Your Name">
                </span>
                </div>
                <div class="form-group">
                <span class="input-icon">
                  <i class="material-icons">mail_outline</i>
                  <input type="email" class="form-control" placeholder="Email" v-model="email">
                </span>
                </div>
                <div class="form-group">
                <span class="input-icon">
                  <i class="material-icons">comment</i>
                  <textarea class="form-control" rows="3" placeholder="Message" v-model="message"></textarea>
                </span>
                </div>
                <button type="button" class="btn btn-primary" @click="contactUs">Send</button>
              </form>
            </div>
            <!-- /Contact Us Form -->

          </div>
        </div>
      </div>

      <OtherModal/>
    </div>

  </div>


</template>

<script>
import {mapGetters} from "vuex"

import OtherModal from '@/components/modal.vue'
import axios from "axios";

export default {
  components: {
    OtherModal,

  },
  data(){
    return{
      name: '',
      email: '',
      message: '',
    }

  },
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {
    this.$store.dispatch("getCompany");
  },
  methods: {
    async contactUs(){
      const formData = {

        "email": this.email,
        "name": this.name,
        "message": this.message,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/contact-us", formData);

        if (response.status == 201) {

          this.$toast.open({
            message: "Contact Us sent successfully",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
          location.reload();
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorLoginEmail = error.response.data.errors.email;
        this.errorLoginPassword = error.response.data.errors.password;

      }

    }

  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


