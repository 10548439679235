<template>

  <div class="modal fade modal-content-right" id="signinModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" role="document">

      <div class="modal-content" id="signinContent" v-show="sigIn">
        <div class="modal-header pb-0">
          <div>
            <h3 class="modal-title">Sign in</h3>
            <em>to your account</em>
          </div>
          <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="login" id="formSignin">
            <div class="form-group">
              <span class="input-icon">
                <i class="material-icons">mail_outline</i>
                <input v-model="loginEmail" type="email" class="form-control" id="signInEmailInput"
                       placeholder="Email address" required>
                <small class="text-danger">{{ errorLoginEmail }}</small>
              </span>
            </div>
            <div class="form-group">
              <span class="input-icon">
                <i class="material-icons">lock_open</i>
                <input v-model="loginPassword" type="password" class="form-control" id="signInPasswordInput"
                       placeholder="Password" required>
                                <small class="text-danger">{{ errorLoginPassword }}</small>

              </span>
            </div>
            <div class="form-group d-flex justify-content-between">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="rememberCheck" checked>
                <label class="custom-control-label" for="rememberCheck">Remember me</label>
              </div>
              <u><a  class="text-primary small" id="showResetContent" @click="showModal(3)">Forgot password
                ?</a></u>
            </div>
            <button type="submit" class="btn btn-primary btn-block">Sign In</button>
          </form>
          <hr>
          <p class="font-italic">Don't have an account ? <u><a href="#" id="#signupContent" @click="showModal(2)">Sign
            Up</a></u></p>
        </div>
      </div>

      <div class="modal-content" id="signupContent" v-show="signUp">
        <div class="modal-header pb-0">
          <div>
            <h3 class="modal-title">Sign up</h3>
            <em>create an account</em>
          </div>
          <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="register" id="formSignup">
            <div class="form-group">
              <input v-model="fullName" type="text" class="form-control" placeholder="Full name" required>
              <small v-if="errorName" class="text-danger">Full name is required *</small>
            </div>
            <div class="form-group">
              <input v-model="email" type="email" class="form-control" id="signUpEmailInput"
                     placeholder="Email address" required>
              <small v-if="errorEmail" class="text-danger">{{ errorEmail }}</small>

            </div>
            <div class="form-group">
              <input v-model="company" type="text" class="form-control" id="signUpCompany" placeholder="Company Name"
                     required>
            </div>
            <div class="form-group">
              <input v-model="telephone" type="text" class="form-control" id="signUpTelephoneInput"
                     placeholder="Telephone Number"
                     required>
              <small v-if="errorPhone" class="text-danger">Telephone is required *</small>

            </div>
            <div class="form-group">
              <input v-model="password" type="password" class="form-control" id="signUpPasswordInput"
                     placeholder="Password" required>
              <small v-if="errorPassword" class="text-danger">Password is required *</small>

            </div>
            <div class="form-group">
              <input v-model="confirm_password" type="password" class="form-control" id="signUpPasswordConfirmInput"
                     placeholder="Confirm password" required>
            </div>
            <button type="submit" class="btn btn-primary btn-block" @click="registerUser">Sign Up</button>
          </form>
          <hr>
          <button class="btn btn-faded-light has-icon btn-sm showSigninContent" type="button" @click="showModal(1)">
            <i class="material-icons">chevron_left</i> Back to sign in
          </button>
        </div>
      </div>

      <div class="modal-content" id="resetContent" v-show="resetPassword">
        <div class="modal-header pb-0">
          <div>
            <h3 class="modal-title">Reset Password</h3>
            <em>enter your email address</em>
          </div>
          <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="sendNewPassword" id="formReset">
            <div class="form-group">
              <span class="input-icon">
                <i class="material-icons">mail_outline</i>
                <input type="email" class="form-control" v-model="forgetPasswordEmail" id="resetEmailInput" placeholder="Email address" required>
              </span>
            </div>
            <button type="submit" class="btn btn-danger btn-block" @click="sendNewPassword">RESET</button>
          </form>
          <hr>
          <button class="btn btn-faded-light has-icon btn-sm showSigninContent" type="button" @click="showModal(1)">
            <i class="material-icons">chevron_left</i> Back to sign in
          </button>
        </div>
      </div>

      <div class="modal-content" id="resetDoneContent" hidden>
        <div class="modal-header pb-0 justify-content-end">
          <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body text-center pt-0">
          <i class="material-icons text-primary display-4">check_circle_outline</i>
          <h3>CHECK YOUR EMAIL</h3>
          <p>We just sent you a letter with instructions to reset your password</p>
          <button class="btn btn-primary btn-block showSigninContent" type="button" @click="showModal(1)">Sign in
          </button>
        </div>
      </div>

    </div>
  </div>
  <!-- SIGN-IN MODAL-->
</template>

<script>
import {mapGetters} from "vuex"
import axios from "axios";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components

  },
  computed: {
    ...mapGetters(["getCompany"]),
  },
  data() {
    return {
      sigIn: true,
      signUp: false,
      resetPassword: false,
      forgetPasswordEmail: '',


      categories: [],
      isDropdownActive: false,
      isMobileMenu: false,

      fullName: '',
      email: '',
      telephone: '',
      company: '',
      password: '',
      confirm_password: '',

      // registration errors
      errorName: '',
      errorEmail: '',
      errorPhone: '',
      errorPassword: '',

      //Login variables
      loginEmail: '',
      loginPassword: '',

      errorLoginEmail: '',
      errorLoginPassword: '',

      countCart: 0,
      countWish: 0,
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
    }
  },
  created() {
  },
  methods: {
    async login() {
      const formData = {

        "email": this.loginEmail,
        "password": this.loginPassword,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/login", formData);

        if (response.status == 201) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);
          const user = JSON.parse(localStorage.getItem("user"));
          this.$toast.open({
            message: "Welcome " + user.name,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
          location.reload();
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorLoginEmail = error.response.data.errors.email;
        this.errorLoginPassword = error.response.data.errors.password;

      }

    },
    async registerUser() {
      const formData = {
        "name": this.fullName,
        "email": this.email,
        "company": this.company,
        "phone": this.telephone,
        "password": this.password,
        "confirm_password": this.confirm_password,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/register", formData);

        if (response.status == 201) {
          this.$toast.open({
            message: "Congratulations, an activation mail has been sent your email " + this.email,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorName = error.response.data.errors.name;
        this.errorEmail = error.response.data.errors.email;
        this.errorPhone = error.response.data.errors.phone;
        this.errorPassword = error.response.data.errors.password;
      }
    },
    async sendNewPassword(){
      const formData = {
        "email": this.forgetPasswordEmail,
      }
      console.log(this.forgetPasswordEmail);

      try {
        const response = await axios.post(this.$store.state.url + "/forget-password", formData);

        if (response.status == 200) {
          this.$toast.open({
            message: "A password reset mail has been sent to your mail " + this.forgetPasswordEmail,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorName = error.response.data.errors.name;

      }
    },
    async getLogOut() {
      const local_token = localStorage.getItem('token');
      const response = await axios.post(this.$store.state.url + "/logout", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      if (response.status == 200) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.$router.push("/login");
      } else {
        console.log('Invalid Request');
      }
    },

    showModal(value) {
      if (value === 1) {
        this.sigIn = true;
        this.signUp = false;
        this.resetPassword = false
      } else if (value == 2) {

        this.sigIn = false;
        this.signUp = true;
        this.resetPassword = false
      } else if (value == 3) {
        this.sigIn = false;
        this.signUp = false;
        this.resetPassword = true;
      } else {
        this.sigIn = true;
        this.signUp = false;
        this.resetPassword = false
      }

    }
  }
}
</script>