<template>
  <div id="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Account</a></li>
            <li class="breadcrumb-item active" aria-current="page">Order Log</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-3">
      <div class="row gutters-3">
        <SideBar/>
        <div class="col mt-3 mt-md-0">
          <div class="card card-style1">
            <div class="card-body">
              <h4>My Orders</h4>
              <hr>
              <div class="table-responsive" id="table-orders">
                <table class="table table-hover">
                  <thead class="thead-light">
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Date</th>
                    <th scope="col" class="text-right">Total</th>
                    <th scope="col" class="text-right">State</th>

                    <th scope="col" class="text-center">Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="order in  orders " :key="order.id">
                    <th scope="row">
                      <router-link :to="{ name: 'OrderDetails', params: { id: order.id }}">
                        {{ order.reference_number }}
                      </router-link>
                    </th>
                    <td>{{ order.created_at }}</td>
                    <td class="text-right">{{ order.currency.symbol }}{{
                        Number(order.total_amount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </td>
                    <td class="text-right">{{ order.order_type.name }}</td>

                    <td class="text-center"> <span class="badge badge-danger" v-if="order.status.id==1"> {{
                        order.status.name
                      }}</span>
                      <span class="badge badge-dark" v-else-if="order.status.id==2"> {{ order.status.name }}</span>
                      <span class="badge badge-warning" v-else-if="order.status.id==3"> {{ order.status.name }}</span>
                      <span class="badge badge-success" v-else-if="order.status.id==4"> {{ order.status.name }}</span>
                    </td>

                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

// import axios from "axios";
import OtherModal from '@/components/modal.vue'
import SideBar from '@/components/dashboard/sidemenu.vue'
import axios from "axios";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,
    SideBar,

  },
  computed: {},
  data() {
    return {
      orders: [],

      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/order", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.orders = response.data.data;
    }


  }

}

</script>


