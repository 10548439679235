<template>
  <div id="app">
  <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'


export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
@import url("./assets/css/style.css");
@import url("./assets/css/swiper.css");
</style>

