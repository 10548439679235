<template>
  <div class="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">About Us</a></li>
            <li class="breadcrumb-item active" aria-current="page">Who we are</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="card card-style1">
        <div class="card-body">

          <!-- About Us -->
          <div class="row">
            <div class="col-md-6 mb-3 mb-md-0">
              <img :src=$store.getters.getAboutUs.home_image alt="About Us" class="w-100">
            </div>
            <div class="col-md-6">
              <h2 v-html=$store.getters.getAboutUs.home_intro></h2>
              <div v-html=$store.getters.getAboutUs.introduction></div>
            </div>
          </div>
          <!-- /About Us -->


          <hr class="my-5">

          <!-- Our Values -->
          <h2 class="text-center mb-4">WHO WE ARE</h2>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/serve.jpg" width="64" height="64" alt="We Serve">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.our_vision></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/run.jpg" width="64" height="64" alt="We Run">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.our_mission></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/adapt.jpg" width="64" height="64" alt="We Adapt">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.our_core></div>

                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/commit.jpg" width="64" height="64" alt="We Commit">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.innovation></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">

              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/commit.jpg" width="64" height="64" alt="We Commit">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.integrity></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/commit.jpg" width="64" height="64" alt="We Commit">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.collaboration></div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/commit.jpg" width="64" height="64" alt="We Commit">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.customer_centric></div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="media">
                <img class="rounded-circle mr-3" src="./img/about-us/commit.jpg" width="64" height="64" alt="We Commit">
                <div class="media-body">
                  <div v-html=$store.getters.getAboutUs.on_health_approach></div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Our Values -->

          <hr class="my-5">

          <!-- Our Purpose -->
          <div class="row">

            <div class="col-md-12">
              <h2>PRODUCT AND SERVICE</h2>
              <div v-html=$store.getters.getAboutUs.products_services></div>
            </div>
          </div>
          <!-- /Our Purpose -->


        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapGetters} from "vuex"


export default {
  components: {},
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {
    this.$store.dispatch("getCompany");
    this.$store.dispatch("getAboutUs");
  },
  methods: {}
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


