<template>
  <div id="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Account</a></li>
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-3">
      <div class="row gutters-3">
        <SideBar/>

        <div class="col mt-3 mt-md-0">
          <div class="card card-style1">
            <div class="card-body">
              <h4>My Profile</h4>
              <hr>
              <form class="form-style-1">
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label for="profileFirstName">Full Name</label>
                    <input type="text" class="form-control" id="profileFirstName" v-model="fullName">
                    <small v-if="errorPhone" class="text-danger">Provide Name *</small>

                  </div>
                  <div class="form-group col-sm-6">
                    <label for="profileLastName">Company Name</label>
                    <input type="text" class="form-control" id="profileLastName" v-model="company_name">
                  </div>
                  <div class="form-group col-sm-6">
                    <label for="profileEmail">Email address</label>
                    <input type="email" class="form-control" id="profileEmail" v-model="email" readonly>
                  </div>
                  <div class="form-group col-sm-6">
                    <label for="profilePhone">Phone Number</label>
                    <input type="text" class="form-control" id="profilePhone" v-model="telephone">
                    <small v-if="errorPhone" class="text-danger">Telephone is required *</small>

                  </div>
                  <div class="form-group col-sm-6">
                    <label for="profileNewPassword">New Password</label>
                    <input type="password" class="form-control" id="profileNewPassword" v-model="password">
                  </div>
                  <div class="form-group col-sm-6">
                    <label for="profileConfirmPassword">Confirm Password</label>
                    <input type="password" class="form-control" id="profileConfirmPassword" v-model="confirm_password">
                  </div>
                  <div class="form-group col-12">
                    <button type="button" class="btn btn-primary" @click="updateProfile">UPDATE PROFILE</button>
                    ||

                    <button type="button" class="btn btn-success">CHANGE PASSWORD</button>

                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>


      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

// import axios from "axios";
import OtherModal from '@/components/modal.vue'
import SideBar from '@/components/dashboard/sidemenu.vue'
import axios from "axios";


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,
    SideBar,

  },
  computed: {},
  data() {
    return {
      fullName: '',
      company_name: '',
      telephone: '',
      email: '',
      password: '',
      confirm_password: '',

      errorPhone: '',
      errorName: '',

      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.populateProfile();
  },
  created() {
    this.getWishlist();
  },
  methods: {
    populateProfile() {
      const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
      this.fullName = user.name;
      this.company_name = user.company_name;
      this.telephone = user.phone;
      this.email = user.email;
    },
    async updateProfile() {
      const user = JSON.parse(localStorage.getItem("user"));
      const local_token = localStorage.getItem('token');

      const formData = {
        "name": this.fullName,
        "company": this.company,
        "phone": this.telephone,
        "user": user.id,

      }
      try {
        const response = await axios.post(this.$store.state.url + "/profile/update", formData, {
          headers: {
            'Authorization': `Bearer ${local_token}`
          }
        });

        if (response.status == 201) {
          this.$toast.open({
            message: "Profile updated Successfully",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
          this.populateProfile();
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorName = error.response.data.errors.name;
        this.errorPhone = error.response.data.errors.phone;
      }

    }
  }

}

</script>


