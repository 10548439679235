<template>
  <div>
    <!-- TOP BAR -->
    <div class="topbar">
      <div class="container-fluid d-flex align-items-center">

        <nav class="nav mr-1 d-none d-md-flex">
          <a class="nav-link nav-link-sm has-icon bg-white pl-0" href="#"><i class="material-icons mr-1">phone</i>
            {{ $store.getters.getCompany.phone }}</a>
          <a class="nav-link nav-link-sm has-icon bg-white" href="#"><i
              class="material-icons mr-1">mail_outline</i>{{ $store.getters.getCompany.email }}</a>
        </nav>

        <nav class="nav nav-circle d-none d-sm-flex">
          <a class="nav-link nav-link-sm nav-icon p-0" :href="$store.getters.getCompany.facebook"><i class="custom-icon"
                                                                                                     data-icon="facebook"
                                                                                                     data-size="17x17"></i></a>
          <a class="nav-link nav-link-sm nav-icon p-0" :href="$store.getters.getCompany.twitter"><i class="custom-icon"
                                                                                                    data-icon="twitter"
                                                                                                    data-size="17x17"></i></a>
          <a class="nav-link nav-link-sm nav-icon p-0" :href="$store.getters.getCompany.instagram"><i
              class="custom-icon" data-icon="instagram" data-size="17x17"></i></a>

          <a class="nav-link nav-link-sm nav-icon p-0" :href="$store.getters.getCompany.linkedin"><span
              style="font-weight: bolder;">in</span></a>


        </nav>


        <nav class="nav nav-gap-x-1 ml-auto mr-1">
          <div class="nav-item nav-pills dropdown">
          </div>
        </nav>
        <div v-if="user">
          <nav class="nav">
            <div class="nav-item nav-pills dropdown dropdown-hover">
              <a class="nav-link nav-link-sm dropdown-toggle has-icon hover" href="#" id="userDropdown" role="button"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                <i class="material-icons mr-1">person</i> Hi, {{ user.name }}
              </a>
              <div class="dropdown-menu dropdown-menu-right font-size-sm" aria-labelledby="userDropdown">
                <router-link :to="{ name: 'Profile'}" class="dropdown-item has-icon pr-5"><i
                    class="material-icons mr-2">person</i>
                  My Profile
                </router-link>
                <router-link :to="{ name: 'Order'}" class="dropdown-item has-icon pr-5"><i class="material-icons mr-2">shopping_cart</i>
                  My Orders
                </router-link>
                <router-link :to="{name: 'Transactions'}" class="dropdown-item has-icon pr-5">
                  <i
                      class="material-icons mr-2">assignment</i> Transaction
                </router-link>
                <router-link :to="{ name: 'Wishlist'}" class="dropdown-item has-icon pr-5"><i
                    class="material-icons mr-2">favorite_border</i>
                  Wishlist
                </router-link>
                <router-link :to="{ name: 'ShippingAddress'}" class="dropdown-item has-icon pr-5"><i
                    class="material-icons mr-2">location_on</i>
                  Shipping Address
                </router-link>


                <div class="dropdown-divider"></div>
                <div @click="getLogOut" class="dropdown-item has-icon pr-5 text-danger" style="cursor: pointer"><i
                    class="material-icons mr-2">exit_to_app</i> Sign Out
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div v-else>
          <a class="btn btn-faded-primary has-icon btn-sm" data-toggle="modal" href="#signinModal">
            <i class="material-icons mr-1">person</i>Sign In
          </a>
        </div>

      </div>
    </div>
    <!-- /TOP BAR -->

    <!-- HEADER -->
    <header>
      <div class="container-fluid">
        <nav class="nav nav-circle d-flex d-lg-none">
          <a href="#menuModal" data-toggle="modal" class="nav-link nav-icon"><i class="material-icons">menu</i></a>
        </nav>
        <nav class="nav ml-3 ml-lg-0">
          <a href="/" class="nav-link has-icon p-0 bg-white">
            <img :src="$store.getters.getCompany.header_logo" alt="Logo" style="width: 30%;">
          </a>
        </nav>

        <nav class="nav nav-main nav-gap-x-1 nav-pills ml-3 d-none d-lg-flex">
          <div class="nav-item dropdown dropdown-hover">

            <a class="nav-item nav-link" href="/">
              Home
            </a>
          </div>
          <div class="nav-item dropdown dropdown-hover">

            <a class="nav-link dropdown-toggle no-caret forwardable" href="#" id="homeDropdown"
               role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              About us
            </a>
            <div class="dropdown-menu" aria-labelledby="homeDropdown">
              <router-link :to="{name: 'WhoWeAre'}" class="dropdown-item">Who We Are</router-link>
              <!--              <router-link :to="{name: 'OurTeam'}" class="dropdown-item">Our Team</router-link>-->
              <router-link :to="{name: 'MileStones'}" class="dropdown-item">Our Milestones</router-link>


            </div>
          </div>
          <div class="nav-item dropdown dropdown-hover">
            <a class="nav-link dropdown-toggle no-caret forwardable" href="#" id="shopDropdown"
               role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Products
            </a>
            <div class="dropdown-menu" aria-labelledby="shopDropdown">
              <div class="dropdown-item" v-for="(category, index) in categories" :key="index">
                <router-link :to="{ name: 'Category', params: { id: category.id }}" class="dropdown-item">
                  {{ category.name }}
                </router-link>
              </div>

            </div>
          </div>

          <a class="nav-item nav-link" href="#">
            Blog
          </a>
          <a class="nav-item nav-link" href="/contact">
            Contact
          </a>
        </nav>
        <nav class="nav nav-circle nav-gap-x-1 ml-auto">
          <a class="nav-link nav-icon" data-toggle="modal" href="#searchModal">
            <i class="material-icons">search</i>
          </a>
          <router-link :to="{ name: 'Wishlist'}" class="nav-link nav-icon has-badge d-none d-sm-flex">
            <i class="material-icons">favorite_border</i>
            <span class="badge badge-pill badge-danger">{{ countWish }}</span>
          </router-link>
          <router-link :to="{ name: 'Cart'}" class="nav-link nav-icon has-badge d-none d-sm-flex">
            <i class="material-icons">shopping_cart</i>
            <span class="badge badge-pill badge-danger">{{ countCart }}</span>
          </router-link>
          <!--          <a class="nav-link nav-icon has-badge" data-toggle="modal" href="cartModal">-->
          <!--            <i class="material-icons">shopping_cart</i>-->
          <!--            <span class="badge badge-pill badge-danger">{{ countCart }}</span>-->
          <!--          </a>-->
        </nav>

      </div>
    </header>
    <MobileMenu/>
    <AuthModal/>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import axios from "axios";

import AuthModal from '@/components/dashboard/authModal.vue';
import MobileMenu from '@/components/mobilemenu.vue';


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AuthModal,
    MobileMenu,

  },
  computed: {
    ...mapGetters(["getCompany"]),
  },
  data() {
    return {
      categories: [],
      isDropdownActive: false,
      isMobileMenu: false,

      countCart: 0,
      countWish: 0,
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
    }
  },
  created() {
    this.$store.dispatch("getCompany");
    this.getCategories();
    this.getCarts();
    this.getWishlist();
  },
  methods: {
    async getCategories() {
      const response = await axios.get(this.$store.state.url + "/category");
      this.categories = response.data.data;
    },
    async getLogOut() {
      // const local_token = localStorage.getItem('token');
      // const response = await axios.post(this.$store.state.url + "/logout", {
      //   headers: {
      //     'Authorization': `Bearer ${local_token}`
      //   }
      // });

      // if (response.status == 201) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');

      this.$router.push("/");
      location.reload();
      // }
    },

    async getCarts() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/cart", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.cart = response.data.data;
      this.countCart = this.cart.cart_items.length;
    },

    async getWishlist() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/wishlist", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.wishlists = response.data.data;
      this.countWish = this.wishlists.length;
    },
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}

li {
  text-decoration: none;
  display: flex;
}
</style>
