<template>
  <div class="home">


    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">About Us</a></li>
            <li class="breadcrumb-item active" aria-current="page">Our Team</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="row gutters-3">
        <div class="col-md-12 col-xl-12">
          <div class="card card-style1">
            <div class="card-body d-flex align-items-center py-2">
              <h4> OUR MANAGEMENT TEAM</h4><br>


            </div>
            <div class="card-body d-flex align-items-center py-2" v-html="$store.getters.getAboutUs.our_team"></div>
            </div>

            <div class="d-grid grid-template-col-sm-2 grid-template-col-xl-3 grid-gap-3 mt-3">
              <div class="card card-style1" v-for="team in teams" :key="team.id">
                <a href="#" class="img img-zoom-in"><img :src=team.image_path class="w-100"
                                                         alt="Blog"></a>
                <div class="card-body">
                  <a href="#" class="h5 card-link d-block">{{ team.name }}</a>
                  <p class="text-muted mb-0">{{ team.position }}</p>
                </div>

              </div>

            </div>
          </div>


        </div>


      </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex"
import axios from "axios";


export default {
  components: {}, data() {
    return {
      teams: [],
    }

  },
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {
    this.$store.dispatch("getCompany");
    this.$store.dispatch('getAboutUs');
    this.getTeam();
  },
  methods: {
    async getTeam() {
      const response = await axios.get(this.$store.state.url + "/team");
      this.teams = response.data;
    }
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


