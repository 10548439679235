<template>
  <div>
    <!-- FOOTER -->
    <div class="footer">
      <div class="container-fluid">
        <div class="d-grid grid-template-col-sm-2 grid-template-col-lg-3">
          <div class="px-3 text-center">
            <h5>Subscribe</h5>
            <form>
              <div class="form-group">
                <input type="email" v-model="email" class="form-control rounded-pill text-center"
                       placeholder="Enter your email">
              </div>
              <button type="button" class="btn btn-primary btn-block rounded-pill" @click="emailSubscribe">SUBSCRIBE
              </button>
            </form>
          </div>
          <div>
            <h5>Customer Service</h5>
            <div class="list-group list-group-flush list-group-no-border list-group-sm">
              <router-link :to="{ name: 'Help'}" class="list-group-item list-group-item-action">
                Help Center
              </router-link>
              <router-link :to="{ name: 'Delivery'}" class="list-group-item list-group-item-action">
                Delivery
              </router-link>
              <router-link :to="{ name: 'Return'}" class="list-group-item list-group-item-action">
                Return Policy
              </router-link>
            </div>
          </div>
          <div>
            <h5>MENU</h5>
            <div class="list-group list-group-flush list-group-no-border list-group-sm">
              <router-link :to="{ name: 'Terms'}" class="list-group-item list-group-item-action">
               Terms and Conditions
              </router-link>
              <router-link :to="{ name: 'Privacy'}" class="list-group-item list-group-item-action">
                Privacy Policy
              </router-link>
              <router-link :to="{ name: 'FAQ'}" class="list-group-item list-group-item-action">
                FAQ
              </router-link>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="copyright">Copyright © 2022 {{ $store.getters.getCompany.name }} All right reserved</div>
    <!-- /FOOTER -->
  </div>

</template>
<script>
import {mapGetters} from "vuex"
import axios from "axios";


export default {
  components: {},
  computed: {
    ...mapGetters(["getCompany"]),
  },
  data() {
    return {
      email: '',
      categories: [],
      isDropdownActive: false,
      isMobileMenu: false,
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  created() {
    this.$store.dispatch("getCompany");
    // this.getCategories();
  },
  methods: {
    async emailSubscribe() {
      const formData = {
        "email": this.email,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/email-subscribe", formData);

        if (response.status == 201) {
          this.$toast.open({
            message: "Congratulations, you have been added to our mailing list " + this.email,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorName = error.response.data.errors.name;
        this.errorEmail = error.response.data.errors.email;
        this.errorPhone = error.response.data.errors.phone;
        this.errorPassword = error.response.data.errors.password;
      }


    },


    // async getCategories() {
    //   const response = await axios.get(this.$store.state.url + "/category");
    //   this.categories = response.data.data;
    // },
    async getLogOut() {
      const local_token = localStorage.getItem('token');
      const response = await axios.post(this.$store.state.url + "/logout", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      if (response.status == 200) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.$router.push("/login");
      } else {
        console.log('Invalid Request');
      }

    }
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>
