import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Category from '../views/category.vue'
import ProductPage from '../views/productPage.vue'
import Product from '../views/product.vue'
import Contact from '../views/contact.vue'
import WhoWeAre from '../views/ourmission.vue'
import CoreValues from '../views/ourvision.vue'
import OurTeam from '../views/ourteam.vue'
import ProductClass from '../views/productClass.vue'
import Cart from '../views/dashboard/cart.vue'
import Checkout from '../views/dashboard/checkout.vue'
import Wishlist from '../views/dashboard/wishlist.vue'
import Profile from '../views/dashboard/profile.vue'
import Order from '../views/dashboard/order.vue'
import ShippingAddress from '../views/dashboard/address.vue'
import Transactions from '../views/dashboard/transactions.vue'
import OrderDetails from '../views/dashboard/order_details.vue'
import VerifyEmail from '../views/verify-email'
import ForgotPassword from '../views/forgot-password'
import ResetPassword from '../views/reset-password'
import FAQ from '../views/faq'
import Privacy from '../views/privacy'
import Delivery from '../views/delivery'
import Return from '../views/return'
import Terms from '../views/terms'
import Help from '../views/help'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/category/:id',
        name: 'Category',
        component: Category
    },
    {
        path: '/product/:id',
        name: 'ProductPage',
        component: ProductPage
    },
    {
        path: '/product-class/:id',
        name: 'ProductClass',
        component: ProductClass
    },
    {
        path: '/products',
        name: 'Product',
        component: Product
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/who-we-are',
        name: 'WhoWeAre',
        component: WhoWeAre
    },
    {
        path: '/our-milestones',
        name: 'MileStones',
        component: CoreValues
    },
    {
        path: '/our-team',
        name: 'OurTeam',
        component: OurTeam
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FAQ
    },
    {
        path: '/privacy-policy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/delivery-policy',
        name: 'Delivery',
        component: Delivery
    },
    {
        path: '/return-policy',
        name: 'Return',
        component: Return
    },
    {
        path: '/help-center',
        name: 'Help',
        component: Help
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout
    },
    {
        path: '/wishlist',
        name: 'Wishlist',
        component: Wishlist
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/order',
        name: 'Order',
        component: Order
    }, {
        path: '/order-details/:id',
        name: 'OrderDetails',
        component: OrderDetails
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions
    },
    {
        path: '/shipping-address',
        name: 'ShippingAddress',
        component: ShippingAddress
    },
    {
        path: '/verify-email',
        name: 'verifyEmail',
        component: VerifyEmail
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: ResetPassword
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    const admin_token = localStorage.getItem('token');
    if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
        if (admin_token) {
            next();
        } else {
            next({path: '/login'});
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    const admin_token = localStorage.getItem('token');
    if (to.matched.some((record) => record.meta.requiresAdminGuest)) {
        if (admin_token) {
            next({path: '/user/profile'});
        } else {
            next();
        }
    } else {
        next();
    }

});

export default router


