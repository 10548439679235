<template>
  <div class="home">


    <div class="breadcrumb-container text-center">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Verification Page</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ status }}</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-4 text-center">

      <h2 v-if="status=='failed'"><span class="text-danger">Oops!</span> {{ message }}</h2>
      <h2 v-if="status=='success'"><span class="text-success">Congratulations!</span> {{ message }}</h2>

      <router-link :to="{name: 'Home'}" class="btn btn-danger has-icon"><i class="material-icons">chevron_left</i> Go
        back
      </router-link>

    </div>


    <OtherModal/>

  </div>

</template>

<script>
import {mapGetters} from "vuex"

import OtherModal from '@/components/modal.vue'
import axios from "axios";

export default {
  components: {
    OtherModal,

  },
  data() {
    return {
      message: '',
      status: '',
    }
  },
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {
    this.$store.dispatch("getCompany");
    this.verifyEmail(this.$route.query);
  },
  methods: {
    async verifyEmail(payload) {
      const response = await axios.get(this.$store.state.url + "/email-verification", {params: payload});
      console.log(response.data.message);
      if (response.data.success == true) {
        this.message = response.data.message;
        this.status = 'success';
      } else {
        this.message = response.data.message;
        this.status = 'failed';
      }
    },
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


