<template>
  <div class="home">

    <!-- /HEADER -->

    <div class="container-fluid mt-3">
      <div class="d-grid grid-template-col-2 grid-template-col-lg-3 grid-gap-1 grid-gap-sm-3">

        <!-- HOME SLIDER -->
        <div class="swiper-container grid-column-start-1 grid-column-end-3 grid-row-start-1 grid-row-end-3"
             id="home-slider">
          <div class="swiper-wrapper">

            <div v-for="banner in banners" :key="banner.id" class="swiper-slide" :data-cover="banner.image_path"
                 data-height="220px 320px 350px 470px 420px">
              <div class="overlay-content overlay-show align-items-start text-white">
                <div class="text-center" v-if="banner.body">
                  <h1 class="animated font-weight-bold" data-animate="fadeDown">{{ banner.body }}<br/></h1>
                  <router-link :to=banner.btnLinks class="btn btn-faded-primary rounded-pill animated mt-4"
                               data-animate="fadeUp"> {{ banner.button }}
                  </router-link>
                </div>
              </div>
            </div>

          </div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev autohide"><i class="material-icons">chevron_left</i></div>
          <div class="swiper-button-next autohide"><i class="material-icons">chevron_right</i></div>
        </div>
        <!-- /HOME SLIDER -->

        <!-- TOP CATEGORIES 1 -->
        <div class="card card-style1 overflow-hidden" v-for="product in adProduct" :key="product.id">
          <router-link :to="{ name: 'ProductPage', params: { id: product.id }}" class="">
            <div class="d-grid grid-template-row-2 grid-template-row-md-none grid-template-col-md-2">
              <div :data-cover="product.default_image.image_path" class="order-md-2"></div>
              <div class="text-center p-3 order-md-1">
                <h6>{{ product.name }}</h6>
                <p class="text-center d-none d-md-block">Model {{ product.model }}</p>
                <div v-if="product.showPrice ==1" class="btn btn-outline-primary rounded-pill stretched-link">Shop Now
                </div>
                <div v-if="product.showPrice ==2 " class="btn btn-outline-danger rounded-pill stretched-link">Request
                  Quote
                </div>

              </div>
            </div>
          </router-link>
        </div>

        <!-- /TOP CATEGORIES 1 -->

      </div>

      <!-- SERVICES BLOCK -->
      <div class="d-grid grid-template-col-sm-2 grid-template-col-xl-4 column-gap-3 row-gap-4 mt-5 mb-5">
        <div class="media">
          <button class="btn btn-icon btn-lg rounded-circle btn-faded-primary active" type="button">
            <i class="material-icons">local_shipping</i>
          </button>
          <div class="media-body ml-3">
            <h6>PROFESSIONAL SHIPPING & HANDLING</h6>
            <span class="text-secondary font-condensed">We handle your shipment professionally</span>
          </div>
        </div>
        <div class="media">
          <button class="btn btn-icon btn-lg rounded-circle btn-faded-primary active" type="button">
            <i class="material-icons">refresh</i>
          </button>
          <div class="media-body ml-3">
            <h6>MONEY BACK GUARANTEE</h6>
            <span class="text-secondary font-condensed">Get the item you ordered, or your money back</span>
          </div>
        </div>
        <div class="media">
          <button class="btn btn-icon btn-lg rounded-circle btn-faded-primary active" type="button">
            <i class="material-icons">security</i>
          </button>
          <div class="media-body ml-3">
            <h6>100% SECURE PAYMENT</h6>
            <span class="text-secondary font-condensed">Your transaction are secure with SSL Encryption</span>
          </div>
        </div>
        <div class="media">
          <button class="btn btn-icon btn-lg rounded-circle btn-faded-primary active" type="button">
            <i class="material-icons">phone</i>
          </button>
          <div class="media-body ml-3">
            <h6>ONLINE SUPPORT</h6>
            <span class="text-secondary font-condensed">Chat with experts or have us call you right away</span>
          </div>
        </div>
      </div>
      <!-- /SERVICES BLOCK -->

      <div class="container-fluid mb-3">
        <div class="card card-style1">
          <div class="card-body">

            <!--            &lt;!&ndash; About Us &ndash;&gt;-->
            <!--            <div class="row">-->
            <!--              <div class="col-md-6 mb-3 mb-md-0">-->
            <!--                <img :src=$store.getters.getAboutUs.home_image alt="About Us" class="w-100">-->
            <!--              </div>-->
            <!--              <div class="col-md-6" style="text-align: justify;">-->
            <!--                <h2 v-html=$store.getters.getAboutUs.home_intro></h2>-->

            <!--                <div v-html="$store.getters.getAboutUs.introduction"></div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            &lt;!&ndash; /About Us &ndash;&gt;-->

            <!-- Our Values -->
            <h2 class="text-center mb-4">Thematic Milestones</h2>
            <div class="row">
              <router-link :to="{ name: 'MileStones'}" class="col-md-2 mb-3"
                           v-for="milestone in $store.getters.getMilestones" :key="milestone.id"
                           style="align-content: center;">
                <div class="media">
                  <img class="rounded-circle mr-3" :src=milestone.image_path width="150" height="150" alt="We Serve">

                </div>
                <span style="text-align: center">{{ milestone.name }}</span>

              </router-link>

            </div>
            <!-- /Our Values -->

          </div>
        </div>
      </div>


      <!-- BRANDS SLIDER -->
      <h4 class="mt-4 text-center">PRODUCT CATEGORIES</h4>
      <div class="card mt-3 border-0">
        <div class="card-body pb-0">
          <div class="swiper-container" id="brandSlider">
            <div class="swiper-wrapper pb-5">
              <div class="swiper-slide card p-3" v-for="category in categories" :key="category.id">
                <router-link :to="{ name: 'Category', params: { id: category.id }}" class="stretched-link">
                  <img :src="category.image_path" alt="brand1" class="card-img-top">
                </router-link>
                <div class="card-body bg-primary-faded text-primary">
                  <h6>{{ category.name }}</h6>
                </div>
              </div>
            </div>

            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
      <!-- /BRANDS SLIDER -->

      <!-- FLASH DEALS -->
      <div class="card card-style1 mt-5">
        <div class="card-body">
          <h5 class="card-title">
            <i class="material-icons align-bottom text-warning">flash_on</i>
            FEATURE PRODUCTS
          </h5>

          <div class="swiper-container" id="deal-slider">
            <div class="swiper-wrapper">

              <div class="swiper-slide card card-product" v-for="product in featureProducts" :key="product.id">
                <div class="card-body">
                  <button class="btn btn-icon btn-text-danger rounded-circle btn-wishlist atw-demo" data-toggle="button"
                          title="Add to wishlist" @click="saveLater(product.id)"></button>
                  <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"><img class="card-img-top"
                                                                                             :src="product.default_image.image_path"
                                                                                             alt="product.name">
                  </router-link>
                  <router-link :to="{ name: 'ProductPage', params: { id: product.id }}" class="card-title card-link">
                    {{ product.name }}
                  </router-link>
                  <span class="badge badge-success">Catalog #{{ product.model }}</span>

                  <div class="price" v-if="product.showPrice == 1">
                    <span class="h4">{{ product.currency.symbol }}{{
                        Number(product.price).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}</span>
                  </div>
                  <div class="price" v-if="product.showPrice == 2">
                    <span class="h4">ON REQUEST</span>
                  </div>
                </div>
                <div class="card-footer" v-if="product.showPrice == 1">
                  <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"
                               class="btn btn-sm rounded-pill btn-faded-primary btn-block atc-demo">View Product
                  </router-link>
                </div>

                <div class="card-footer" v-if="product.showPrice == 2">
                  <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"
                               class="btn btn-sm rounded-pill btn-faded-danger btn-block atc-demo">View Product
                  </router-link>
                </div>
              </div>

            </div>
            <div class="swiper-button-prev"><i class="material-icons">chevron_left</i></div>
            <div class="swiper-button-next"><i class="material-icons">chevron_right</i></div>
          </div>
        </div>
      </div>
      <!-- /FLASH DEALS -->


      <!-- BEST SELLERS & TOP CATEGORIES 2 -->
      <div
          class="d-grid grid-template-col-2 grid-template-col-lg-5 grid-template-col-xl-4 grid-gap-2 grid-gap-xl-3 mt-4 mb-3">
        <div
            class="card card-style1 grid-column-start-1 grid-column-end-3 grid-column-end-sm-2 grid-column-end-lg-3 grid-column-end-xl-2">
          <div class="card-body">
            <h5 class="card-title">BEST DEALS</h5>
            <ul class="list-group list-group-flush list-group-sms">
              <li class="list-group-item px-0" v-for="product in bestDeals" :key="product.id">
                <div class="media">
                  <div>
                    <img :src="product.default_image.image_path" width="75" alt="product">
                  </div>
                  <div class="media-body ml-3">
                    <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"
                                 class="card-link text-secondary">{{ product.name }}
                    </router-link>
                    <div class="price"><span>{{ product.currency.symbol }}{{
                        Number(product.price).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}</span></div>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
        <div class="card card-style1" v-for="item in productClass" :key="item.id">
          <div class="card-link">
            <img class="card-img-top" :src="item.image_path" alt="item.name">
            <div class="card-body bg-success-faded text-dark">
              <router-link :to="{ name: 'ProductClass', params: { id: item.id }}"><h5 class="mb-0">{{ item.name }}</h5>
              </router-link>
            </div>
          </div>

        </div>

      </div>

    </div>


    <!--    &lt;!&ndash; SIGN-IN MODAL &ndash;&gt;-->
    <!--    <div class="modal fade modal-content-right" id="signinModal" tabindex="-1" role="dialog">-->
    <!--      <div class="modal-dialog modal-sm" role="document">-->

    <!--        <div class="modal-content" id="signinContent">-->
    <!--          <div class="modal-header pb-0">-->
    <!--            <div>-->
    <!--              <h3 class="modal-title">Sign in</h3>-->
    <!--              <em>to your account</em>-->
    <!--            </div>-->
    <!--            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">-->
    <!--              <i class="material-icons">close</i>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div class="modal-body">-->
    <!--            <form id="formSignin">-->
    <!--              <div class="form-group">-->
    <!--              <span class="input-icon">-->
    <!--                <i class="material-icons">mail_outline</i>-->
    <!--                <input type="email" class="form-control" id="signInEmailInput" placeholder="Email address" required>-->
    <!--              </span>-->
    <!--              </div>-->
    <!--              <div class="form-group">-->
    <!--              <span class="input-icon">-->
    <!--                <i class="material-icons">lock_open</i>-->
    <!--                <input type="password" class="form-control" id="signInPasswordInput" placeholder="Password" required>-->
    <!--              </span>-->
    <!--              </div>-->
    <!--              <div class="form-group d-flex justify-content-between">-->
    <!--                <div class="custom-control custom-checkbox">-->
    <!--                  <input type="checkbox" class="custom-control-input" id="rememberCheck" checked>-->
    <!--                  <label class="custom-control-label" for="rememberCheck">Remember me</label>-->
    <!--                </div>-->
    <!--                <u><a href="#" class="text-primary small" id="showResetContent">Forgot password ?</a></u>-->
    <!--              </div>-->
    <!--              <button type="submit" class="btn btn-primary btn-block">Sign In</button>-->
    <!--            </form>-->
    <!--            <hr>-->
    <!--            <p class="font-italic">Don't have an account ? <u><a href="#" id="showSignupContent">Sign Up</a></u></p>-->
    <!--            <hr>-->
    <!--            <div class="text-center">-->
    <!--              <p class="font-italic">Or sign in via</p>-->
    <!--              <button class="btn btn-icon btn-faded-primary rounded-circle" data-toggle="tooltip" title="Facebook"-->
    <!--                      data-container="#signinContent">-->
    <!--                <i class="custom-icon" data-icon="facebook" data-size="20x20"></i>-->
    <!--              </button>-->
    <!--              <button class="btn btn-icon btn-faded-info rounded-circle" data-toggle="tooltip" title="Twitter"-->
    <!--                      data-container="#signinContent">-->
    <!--                <i class="custom-icon" data-icon="twitter" data-size="20x20"></i>-->
    <!--              </button>-->
    <!--              <button class="btn btn-icon btn-faded-danger rounded-circle" data-toggle="tooltip" title="Google"-->
    <!--                      data-container="#signinContent">-->
    <!--                <i class="custom-icon" data-icon="gplus" data-size="20x20"></i>-->
    <!--              </button>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="modal-content" id="showSignupContent" hidden>-->
    <!--          <div class="modal-header pb-0">-->
    <!--            <div>-->
    <!--              <h3 class="modal-title">Sign up</h3>-->
    <!--              <em>create an account</em>-->
    <!--            </div>-->
    <!--            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">-->
    <!--              <i class="material-icons">close</i>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div class="modal-body">-->
    <!--            <form id="formSignup">-->
    <!--              <div class="form-group">-->
    <!--                <input type="text" class="form-control" id="signUpNameInput" placeholder="Full name" required>-->
    <!--              </div>-->
    <!--              <div class="form-group">-->
    <!--                <input type="email" class="form-control" id="signUpEmailInput" placeholder="Email address" required>-->
    <!--              </div>-->
    <!--              <div class="form-group">-->
    <!--                <input type="password" class="form-control" id="signUpPasswordInput" placeholder="Password" required>-->
    <!--              </div>-->
    <!--              <div class="form-group">-->
    <!--                <input type="password" class="form-control" id="signUpPasswordConfirmInput"-->
    <!--                       placeholder="Confirm password" required>-->
    <!--              </div>-->
    <!--              <button type="submit" class="btn btn-primary btn-block">Sign Up</button>-->
    <!--            </form>-->
    <!--            <hr>-->
    <!--            <button class="btn btn-faded-light has-icon btn-sm showSigninContent" type="button">-->
    <!--              <i class="material-icons">chevron_left</i> Back to sign in-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="modal-content" id="resetContent" hidden>-->
    <!--          <div class="modal-header pb-0">-->
    <!--            <div>-->
    <!--              <h3 class="modal-title">Reset Password</h3>-->
    <!--              <em>enter your email address</em>-->
    <!--            </div>-->
    <!--            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">-->
    <!--              <i class="material-icons">close</i>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div class="modal-body">-->
    <!--            <form id="formReset">-->
    <!--              <div class="form-group">-->
    <!--              <span class="input-icon">-->
    <!--                <i class="material-icons">mail_outline</i>-->
    <!--                <input type="email" class="form-control" id="resetEmailInput" placeholder="Email address" required>-->
    <!--              </span>-->
    <!--              </div>-->
    <!--              <button type="submit" class="btn btn-danger btn-block">RESET</button>-->
    <!--            </form>-->
    <!--            <hr>-->
    <!--            <button class="btn btn-faded-light has-icon btn-sm showSigninContent" type="button">-->
    <!--              <i class="material-icons">chevron_left</i> Back to sign in-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="modal-content" id="resetDoneContent" hidden>-->
    <!--          <div class="modal-header pb-0 justify-content-end">-->
    <!--            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">-->
    <!--              <i class="material-icons">close</i>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div class="modal-body text-center pt-0">-->
    <!--            <i class="material-icons text-primary display-4">check_circle_outline</i>-->
    <!--            <h3>CHECK YOUR EMAIL</h3>-->
    <!--            <p>We just sent you a letter with instructions to reset your password</p>-->
    <!--            <button class="btn btn-primary btn-block showSigninContent" type="button">Sign in</button>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; /SIGN-IN MODAL &ndash;&gt;-->

    <!--    &lt;!&ndash; MENU MODAL &ndash;&gt;-->
    <!--    <div class="modal fade modal-content-left" id="menuModal" tabindex="-1" role="dialog" aria-hidden="true">-->
    <!--      <div class="modal-dialog modal-dialog-scrollable" role="document">-->
    <!--        <div class="modal-content">-->
    <!--          <div class="modal-header align-items-center border-bottom shadow-sm z-index-1">-->
    <!--            <a href="#"><img src="./img/logo.png" alt="Logo" height="40"></a>-->
    <!--            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">-->
    <!--              <i class="material-icons">close</i>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div class="modal-body px-0 scrollbar-width-thin">-->
    <!--            <ul class="menu" id="menu">-->
    <!--              <li class="no-sub"><a href="index.html"><i class="material-icons">home</i> Home</a></li>-->
    <!--              <li class="no-sub mm-active"><a href="index2.html"><i class="material-icons">home_work</i> Home layout-->
    <!--                2</a></li>-->
    <!--              <li class="no-sub"><a href="index3.html"><i class="material-icons">phonelink</i> Electronics Store</a>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <a href="#" class="has-arrow"><i class="material-icons">shopping_cart</i> Shop</a>-->
    <!--                <ul>-->
    <!--                  <li><a href="shop-categories.html">Shop Categories</a></li>-->
    <!--                  <li><a href="shop-grid.html">Shop Grid</a></li>-->
    <!--                  <li><a href="shop-list.html">Shop List</a></li>-->
    <!--                  <li><a href="cart.html">Cart</a></li>-->
    <!--                  <li>-->
    <!--                    <a href="#" class="has-arrow">Checkout</a>-->
    <!--                    <ul>-->
    <!--                      <li><a href="checkout-shipping.html">Checkout Shipping</a></li>-->
    <!--                      <li><a href="checkout-payment.html">Checkout Payment</a></li>-->
    <!--                      <li><a href="checkout-review.html">Checkout Review</a></li>-->
    <!--                      <li><a href="checkout-complete.html">Checkout Complete</a></li>-->
    <!--                    </ul>-->
    <!--                  </li>-->
    <!--                  <li><a href="shop-single.html">Single Product</a></li>-->
    <!--                </ul>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <a href="#" class="has-arrow"><i class="material-icons">person</i> Account</a>-->
    <!--                <ul>-->
    <!--                  <li><a href="account-signin.html">Sign In / Sign Up</a></li>-->
    <!--                  <li><a href="account-profile.html">Profile Page</a></li>-->
    <!--                  <li><a href="account-orders.html">Orders List</a></li>-->
    <!--                  <li><a href="account-order-detail.html">Order Detail</a></li>-->
    <!--                  <li><a href="account-wishlist.html" class="has-badge">Wishlist <span-->
    <!--                      class="badge badge-primary badge-pill">3</span></a></li>-->
    <!--                  <li><a href="account-address.html">Address</a></li>-->
    <!--                </ul>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <a href="#" class="has-arrow"><i class="material-icons">rss_feed</i> Blog</a>-->
    <!--                <ul>-->
    <!--                  <li><a href="blog-grid.html">Post Grid</a></li>-->
    <!--                  <li><a href="blog-list.html">Post List</a></li>-->
    <!--                  <li><a href="blog-single.html">Single Post</a></li>-->
    <!--                </ul>-->
    <!--              </li>-->
    <!--              <li>-->
    <!--                <a href="#" class="has-arrow"><i class="material-icons">file_copy</i> Pages</a>-->
    <!--                <ul>-->
    <!--                  <li><a href="about.html">About Us</a></li>-->
    <!--                  <li><a href="contact.html">Contact Us</a></li>-->
    <!--                  <li><a href="compare.html">Compare</a></li>-->
    <!--                  <li><a href="faq.html">Help / FAQ</a></li>-->
    <!--                  <li><a href="404.html">404 Not Found</a></li>-->
    <!--                </ul>-->
    <!--              </li>-->
    <!--              <li class="no-sub"><a href="components.html"><i class="material-icons">check_box_outline_blank</i>-->
    <!--                Components</a></li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; /MENU MODAL &ndash;&gt;-->

    <!--    &lt;!&ndash; SEARCH MODAL &ndash;&gt;-->
    <!--    <div class="modal fade" id="searchModal" tabindex="-1" role="dialog">-->
    <!--      <div class="modal-dialog modal-lg" role="document">-->
    <!--        <div class="modal-content">-->
    <!--          <div class="modal-body p-1 p-lg-3">-->
    <!--            <form>-->
    <!--              <div class="input-group input-group-lg input-group-search">-->
    <!--                <div class="input-group-prepend">-->
    <!--                  <button class="btn btn-text-secondary btn-icon btn-lg rounded-circle" type="button"-->
    <!--                          data-dismiss="modal">-->
    <!--                    <i class="material-icons">chevron_left</i>-->
    <!--                  </button>-->
    <!--                </div>-->
    <!--                <input type="search" class="form-control form-control-lg border-0 shadow-none mx-1 px-0 px-lg-3"-->
    <!--                       id="searchInput" placeholder="Search..." required>-->
    <!--                <div class="input-group-append">-->
    <!--                  <button class="btn btn-text-secondary btn-icon btn-lg rounded-circle" type="submit">-->
    <!--                    <i class="material-icons">search</i>-->
    <!--                  </button>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </form>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; /SEARCH MODAL &ndash;&gt;-->

    <!--    &lt;!&ndash; CART MODAL &ndash;&gt;-->
    <!--    <div class="modal fade modal-content-right modal-cart" id="cartModal" tabindex="-1" role="dialog"-->
    <!--         aria-labelledby="cartModalLabel" aria-hidden="true">-->
    <!--      <div class="modal-dialog modal-dialog-scrollable" role="document">-->
    <!--        <div class="modal-content">-->
    <!--          <div class="modal-header border-bottom">-->
    <!--            <h5 class="modal-title" id="cartModalLabel">You have 4 items in your cart</h5>-->
    <!--            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">-->
    <!--              <i class="material-icons">close</i>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <div class="modal-body scrollbar-width-thin">-->
    <!--            <ul class="list-group list-group-flush">-->
    <!--              <li class="list-group-item px-0">-->
    <!--                <div class="media">-->
    <!--                  <a href="shop-single.html" class="mr-2"><img src="./img/products/1_small.jpg" width="50" height="50"-->
    <!--                                                               alt="Hanes Hooded Sweatshirt"></a>-->
    <!--                  <div class="media-body">-->
    <!--                    <a href="shop-single.html" class="d-block text-body" title="Hanes Hooded Sweatshirt">Hanes Hooded-->
    <!--                      Sweatshirt</a>-->
    <!--                    <em class="text-muted">1 x $18.56</em>-->
    <!--                  </div>-->
    <!--                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"><i-->
    <!--                      class="material-icons">close</i></button>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li class="list-group-item px-0">-->
    <!--                <div class="media">-->
    <!--                  <a href="shop-single.html" class="mr-2"><img src="./img/products/2_small.jpg" width="50" height="50"-->
    <!--                                                               alt="The Flash Logo T-Shirt"></a>-->
    <!--                  <div class="media-body">-->
    <!--                    <a href="shop-single.html" class="d-block text-body" title="The Flash Logo T-Shirt">The Flash Logo-->
    <!--                      T-Shirt</a>-->
    <!--                    <em class="text-muted">2 x $16.64</em>-->
    <!--                  </div>-->
    <!--                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"><i-->
    <!--                      class="material-icons">close</i></button>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li class="list-group-item px-0">-->
    <!--                <div class="media">-->
    <!--                  <a href="shop-single.html" class="mr-2"><img src="./img/products/3_small.jpg" width="50" height="50"-->
    <!--                                                               alt="Open Front Cropped Cardigans"></a>-->
    <!--                  <div class="media-body">-->
    <!--                    <a href="shop-single.html" class="d-block text-body" title="Open Front Cropped Cardigans">Open Front-->
    <!--                      Cropped Cardigans</a>-->
    <!--                    <em class="text-muted">1 x $15.20</em>-->
    <!--                  </div>-->
    <!--                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"><i-->
    <!--                      class="material-icons">close</i></button>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--              <li class="list-group-item px-0">-->
    <!--                <div class="media">-->
    <!--                  <a href="shop-single.html" class="mr-2"><img src="./img/products/4_small.jpg" width="50" height="50"-->
    <!--                                                               alt="Cotton Fleece Long Hoodie"></a>-->
    <!--                  <div class="media-body">-->
    <!--                    <a href="shop-single.html" class="d-block text-body" title="Cotton Fleece Long Hoodie">Cotton Fleece-->
    <!--                      Long Hoodie</a>-->
    <!--                    <em class="text-muted">1 x $85.00</em>-->
    <!--                  </div>-->
    <!--                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"><i-->
    <!--                      class="material-icons">close</i></button>-->
    <!--                </div>-->
    <!--              </li>-->
    <!--            </ul>-->

    <!--          </div>-->
    <!--          <div class="modal-footer border-top">-->
    <!--            <div class="mr-auto">-->
    <!--              <em>Subtotal</em>-->
    <!--              <h5 class="mb-0 text-dark font-weight-bold font-condensed">$152.04</h5>-->
    <!--            </div>-->
    <!--            <a href="cart.html" class="btn btn-faded-success">View Cart</a>-->
    <!--            <a href="shipping.html" class="btn btn-success">Checkout</a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; /CART MODAL &ndash;&gt;-->

    <OtherModal/>
  </div>
</template>

<script>

import axios from "axios";
import OtherModal from '@/components/modal.vue'


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,

  },
  computed: {},
  data() {
    return {
      featureProducts: [],
      categories: [],
      bestDeals: [],
      banners: [],
      adProduct: [],
      productClass: [],
      buttonColor: 'danger',
    }
  },
  created() {
    this.$store.dispatch('getCategories');
    this.$store.dispatch('getCompany');
    this.$store.dispatch('getAboutUs');
    this.$store.dispatch('getMilestones');
    this.getFeatureProducts();
    this.getCategories();
    this.getBestDeals();
    this.getBanners();
    this.getAdProducts();
    this.getProductClass();
  },
  methods: {
    async getFeatureProducts() {
      const response = await axios.get(this.$store.state.url + "/products/featured");
      this.featureProducts = response.data.data;

      setTimeout(() => {
        window.App.dealSlider2('#deal-slider');
      }, 0);
    },

    async getAdProducts() {
      const response = await axios.get(this.$store.state.url + "/products/adproduct");
      this.adProduct = response.data.data;
      setTimeout(() => {
        window.App.init();
      }, 0);
    },

    async getCategories() {
      const response = await axios.get(this.$store.state.url + "/category");
      this.categories = response.data.data;
      setTimeout(() => {
        window.App.init();
        window.App.brandSlider('#brandSlider');
      }, 0);
    },
    async getBestDeals() {
      const response = await axios.get(this.$store.state.url + "/products/bestDeal");
      this.bestDeals = response.data.data;
    },
    async getBanners() {
      const response = await axios.get(this.$store.state.url + "/banners");
      this.banners = response.data;

      setTimeout(() => {
        window.App.init();
        window.App.homeSlider('#home-slider');
      }, 0);
    }
    ,
    async getProductClass() {
      const response = await axios.get(this.$store.state.url + "/product_class");
      this.productClass = response.data;
    },

    async saveLater(value) {
      const local_token = localStorage.getItem('token');
      const formData = {
        "product": value,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/wishlist", formData, {
          headers: {
            'Authorization': `Bearer ${local_token}`
          }
        });
        if (response.status == 201) {
          this.$toast.open({
            message: 'Item added to Wish list successfully',
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })

        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
      }

    },
  },


}

</script>


