<template>
  <div class="home">


    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="row gutters-3">
        <div class="col-md-12 col-xl-12">
          <div class="card card-style1">
            <div class="card-body d-flex align-items-center py-2">
              <h4>{{ page.page_title }}</h4><br>


            </div>
            <div class="col-12 card-body" v-html="page.page_content"></div>
          </div>
        </div>


      </div>


    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex"
import axios from "axios";


export default {
  components: {}, data() {
    return {
      page: [],
    }

  },
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {

    this.getPage();
  },
  methods: {
    async getPage() {
      const response = await axios.get(this.$store.state.url + "/terms");
      this.page = response.data;
    }
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


