<template>
  <div class="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">About Us</a></li>
            <li class="breadcrumb-item active" aria-current="page">Our Developmental Milestone</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="card card-style1">
        <div class="card-body">

          <!-- About Us -->
          <div class="row">

            <div class="col-md-12">
              <h2 class="text-center">OUR  DEVELOPMENTAL MILESTONES</h2>

              <!-- Accordion Colors -->
              <div class="accordion accordion-caret accordion-no-border" id="accordion-colors">
                <div class="card card-success" v-for="milestone in $store.getters.getMilestones" :key="milestone.id">
                  <a class="card-header h4" data-toggle="collapse" v-bind:href="milestone.id"  aria-expanded="true">

                   {{ milestone.name}}
                  </a>
                  <div :id=milestone.id class="collapse show" data-parent="#accordion-colors">
                    <div class="row">
                    <div class="col-3"><img  :src=milestone.image_path style="width: 150px; height: 150px;"></div>
                    <div class="col-9 card-body" v-html="milestone.detail" style="text-align: justify;"></div></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- /About Us -->


        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapGetters} from "vuex"


export default {
  components: {
  },
  computed: {
    ...mapGetters(["getCompany"]),
  },

  created() {
    this.$store.dispatch("getCompany");
    this.$store.dispatch("getMilestones");
  },
  methods: {

  }
}
</script>
<style scoped>
a {
  text-decoration: none;
  text-decoration-color: none;
}
</style>


