<template>
  <div id="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Account</a></li>
            <li class="breadcrumb-item active" aria-current="page">Wishlist</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid my-3">
      <div class="row gutters-3">
        <SideBar/>

        <div class="col mt-3 mt-md-0">
          <div class="card card-style1">
            <div class="card-body">
              <h4>My Wishlist</h4>
              <ul class="list-group list-group-flush list-group-sm mt-3">
                <li class="list-group-item px-0 d-flex align-items-center" v-for="wishlist in wishlists" :key="wishlist.id">
                  <div class="media">
                    <router-link :to="{ name: 'ProductPage', params: { id: wishlist.products.id }}" class="card-title card-link">
                      <img :src="wishlist.products.default_image.image_path" alt="product" width="75">
                    </router-link>
                    <div class="media-body ml-3">
                      <router-link :to="{ name: 'ProductPage', params: { id: wishlist.products.id }}" class="card-title card-link">
                        {{ wishlist.products.name }}</router-link>
                      <div class="price"><span>{{ wishlist.products.currency.symbol}}{{ wishlist.products.price}}</span></div>
                      <router-link :to="{ name: 'ProductPage', params: { id: wishlist.products.id }}" class="card-title card-link">
                        Add to Cart</router-link>
                    </div>
                  </div>
                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle ml-auto" type="button" @click="deleteWishlist(wishlist.products.id)"><i class="material-icons">close</i></button>
                </li>


              </ul>
              <div class="custom-control custom-checkbox mt-3">
                <input type="checkbox" class="custom-control-input" id="informCheck" checked="">
                <label class="custom-control-label" for="informCheck">Inform me when item from my wishlist is available</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OtherModal/>
  </div>

</template>

<script>

import axios from "axios";
import OtherModal from '@/components/modal.vue'
import SideBar from '@/components/dashboard/sidemenu.vue'


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,
    SideBar,

  },
  computed: {
  },
  data() {
    return {
     wishlists: [],
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  created() {
   this.getWishlist();
  },
  methods: {
    async getWishlist() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/wishlist", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.wishlists = response.data.data;
    },

    async deleteWishlist(value) {
      const local_token = localStorage.getItem('token');
      const response = await axios.delete(this.$store.state.url + "/wishlist/" + value, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        },
      });
      if (response.status == 201) {
        this.$toast.open({
          message: 'Item deleted from wishlist successfully',
          type: "success",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.getWishlist();
      }
    },
       // setCurrency(value) {
    //
    // }

  }

}

</script>


