import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        categories: [],
        company: [],
        url: process.env.VUE_APP_API_URL,
        cart: [],
        logout: [],
        aboutUs: [],
        countCart: 0,
        payStackDetail: {
            PUBLIC_KEY: "pk_test_8dd63d536ff5bfbd996c257d080a2a419d3a86c4",
        },
        milestones: [],
    },
    getters: {
        getCompany(state) {
            return state.company;
        },
        getPayStack(state) {
            return state.payStackDetail;
        },
        getCategories(state) {
            return state.categories;
        },
        getAboutUs(state) {
            return state.aboutUs;
        },
        getMilestones(state) {
            return state.milestones;
        },
        getCart(state) {
            return state.cart;
        }
    },
    mutations: {
        setCompany(state, payload) {
            state.company = payload
        },
        setPayStack(state, payload) {
            state.payStackDetail = payload
        },
        setCategories(state, payload) {
            state.categories = payload
        },
        setAboutUs(state, payload) {
            state.aboutUs = payload
        },
        setMilestones(state, payload) {
            state.milestones = payload
        },
        setCart(state, payload) {
            state.cart = payload
        }
    },
    actions: {
        async getCompany(context) {
            const response = await axios.get(context.state.url + "/company");
            context.commit("setCompany", response.data);
        },
        async getCategories(context) {
            const response = await axios.get(context.state.url + "/category");
            context.commit("setCategories", response.data);
        },
        async getAboutUs(context) {
            const response = await axios.get(context.state.url + "/about");
            context.commit("setAboutUs", response.data);
        },
        async getMilestones(context) {
            const response = await axios.get(context.state.url + "/milestones");
            context.commit("setMilestones", response.data);
        },
        async getCarts() {
            const local_token = localStorage.getItem('token');
            const response = await axios.get(this.$store.state.url + "/cart", {
                headers: {
                    'Authorization': `Bearer ${local_token}`
                }
            });
            this.cart = response.data.data;
            this.countCart = this.cart.cart_items.length;
        },


    },
    modules: {}
})
