<template>
  <div>
    <!-- SIGN-IN MODAL -->


    <!-- MENU MODAL -->
    <div class="modal fade modal-content-left" id="menuModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header align-items-center border-bottom shadow-sm z-index-1">
            <a href="#"><img :src="$store.getters.getCompany.header_logo" alt="Logo" height="40"></a>
            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="modal-body px-0 scrollbar-width-thin">
            <ul class="menu" id="menu">
              <li class="no-sub"><a href="/"><i class="material-icons">home</i> Home</a></li>

              <li>
                <a href="#" class="has-arrow"><i class="material-icons">person</i> About Us</a>
                <ul>
                  <li><a href="/who-we-are">Who We Are</a></li>
                  <li><a href="/our-core-values">Our Core Values</a></li>
                  <li><a href="/our-team">Our Team</a></li>

                </ul>
              </li>
              <li>
                <a href="#" class="has-arrow"><i class="material-icons">menu</i> Products</a>
                <ul>
                  <li v-for="category in categories" :key="category.id">
                    <router-link :to="{ name: 'Category', params: { id: category.id }}">{{ category.name }}
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="no-sub"><a href="#"><i class="material-icons">rss_feed</i>
                Blog</a></li>
              <li class="no-sub"><a href="/contact"><i class="material-icons">phone</i>
                Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- /MENU MODAL -->

    <!-- SEARCH MODAL -->
    <div class="modal fade" id="searchModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-1 p-lg-3">
            <form>
              <div class="input-group input-group-lg input-group-search">
                <div class="input-group-prepend">
                  <button class="btn btn-text-secondary btn-icon btn-lg rounded-circle" type="button"
                          data-dismiss="modal">
                    <i class="material-icons">chevron_left</i>
                  </button>
                </div>
                <input type="search" class="form-control form-control-lg border-0 shadow-none mx-1 px-0 px-lg-3"
                       id="searchInput" placeholder="Search..." required>
                <div class="input-group-append">
                  <button class="btn btn-text-secondary btn-icon btn-lg rounded-circle" type="submit">
                    <i class="material-icons">search</i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /SEARCH MODAL -->

    <!-- CART MODAL -->
    <div class="modal fade modal-content-right modal-cart" id="cartModal" tabindex="-1" role="dialog"
         aria-labelledby="cartModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom">
            <h5 class="modal-title" id="cartModalLabel">You have {{ countCart }} items in your cart</h5>
            <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="modal-body scrollbar-width-thin">
            <ul class="list-group list-group-flush">
              <!--              <li class="list-group-item px-0" v-for="(cartItem, index) in cart.cart_items" :key="index">-->
              <!--                <div class="media">-->
              <!--                  <a href="#" class="mr-2"><img :src="cartItem.product.default_image.image_path" width="50" height="50"-->
              <!--                                                alt="cartItem.product.name">{{ cartItem.product.name }}</a>-->
              <!--                  <div class="media-body">-->
              <!--                    <a href="#" class="d-block text-body" title="Hanes Hooded Sweatshirt"></a>-->
              <!--                    <em class="text-muted">{{ cartItem.qty }} x {{ cartItem.product.currency.symbol }}-->
              <!--                      {{ cartItem.product.price }}</em>-->
              <!--                  </div>-->
              <!--                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"><i-->
              <!--                      class="material-icons">close</i></button>-->
              <!--                </div>-->
              <!--              </li>-->

              <li class="list-group-item px-0" v-for="(cartItem, index) in cart.cart_items" :key="index">
                <div class="media">
                  <a href="#" class="mr-2"><img :src="cartItem.product.default_image.image_path" width="50" height="50"
                                                alt="cartItem.product.name"></a>
                  <div class="media-body">
                    <a href="#" class="d-block text-body" title="cartItem.product.name">{{ cartItem.product.name }}</a>
                    <em class="text-muted">{{ cartItem.qty }} x {{ cartItem.product.currency.symbol }}
                      {{
                        Number(cartItem.product.price).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}</em>
                  </div>
                  <button class="btn btn-icon btn-sm btn-text-danger rounded-circle" type="button"
                          @click="deleteItem(cartItem.id)"><i class="material-icons">close</i></button>
                </div>
              </li>


            </ul>

          </div>
          <div class="modal-footer border-top">
            <div class="mr-auto">
              <em>Subtotal</em>
              <h5 class="mb-0 text-dark font-weight-bold font-condensed">
                ₦{{
                  Number(totalItem).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                }}</h5>
            </div>
            <router-link :to="{ name: 'Cart'}" class="btn btn-faded-success" data-dismiss="modal">View Cart
            </router-link>
            <router-link :to="{name: 'Checkout' }" class="btn btn-success" data-dismiss="modal">Checkout</router-link>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /CART MODAL -->


  <!--  &lt;!&ndash; QUICK VIEW MODAL &ndash;&gt;-->
  <!--  <div class="modal fade" id="quickviewModal" tabindex="-1" role="dialog">-->
  <!--    <div class="modal-dialog modal-lg" role="document">-->
  <!--      <div class="modal-content">-->
  <!--        <div class="modal-body">-->
  <!--          <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle float-right" type="button" data-dismiss="modal">-->
  <!--            <i class="material-icons">close</i>-->
  <!--          </button>-->
  <!--          <div class="row">-->
  <!--            <div class="col-lg-6">-->
  <!--              <div class="swiper-container" id="quickviewSlider">-->
  <!--                <div class="swiper-wrapper">-->
  <!--                  <img alt="Product" class="swiper-slide">-->
  <!--                  <img  alt="Product" class="swiper-slide">-->
  <!--                  <img  alt="Product" class="swiper-slide">-->
  <!--                </div>-->
  <!--                <div class="swiper-pagination"></div>-->
  <!--                <div class="swiper-button-prev autohide"><i class="material-icons">chevron_left</i></div>-->
  <!--                <div class="swiper-button-next autohide"><i class="material-icons">chevron_right</i></div>-->
  <!--                <button class="btn btn-icon btn-text-danger rounded-circle btn-wishlist atw-demo" data-toggle="button" title="Add to wishlist" data-title="Hanes Men's Pullover EcoSmart Fleece Hooded Sweatshirt"></button>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div class="col-lg-6 mt-3 mt-lg-0">-->
  <!--              <h4 class="card-title font-weight-normal">Hanes Men's Pullover EcoSmart Fleece Hooded Sweatshirt</h4>-->
  <!--              <h6><span class="rating" data-value="4.5"></span> <a href="javascript:void(0)">2 reviews</a></h6>-->
  <!--              <h3 class="price">-->
  <!--                <span class="del">$140.00</span>-->
  <!--                <span >$120.00</span>-->
  <!--              </h3>-->
  <!--              <form>-->
  <!--                <div class="form-group">-->
  <!--                  <label class="font-weight-bold d-block">Size</label>-->
  <!--                  <div class="btn-group btn-group-sm btn-group-toggle btn-group-circle" data-toggle="buttons">-->
  <!--                    <label class="btn btn-outline-secondary" data-toggle="tooltip" title="Small" data-container="#quickviewModal">-->
  <!--                      <input type="radio" name="quickViewProductSize">S-->
  <!--                    </label>-->
  <!--                    <label class="btn btn-outline-secondary active" data-toggle="tooltip" title="Medium" data-container="#quickviewModal">-->
  <!--                      <input type="radio" name="quickViewProductSize" checked>M-->
  <!--                    </label>-->
  <!--                    <label class="btn btn-outline-secondary" data-toggle="tooltip" title="Large" data-container="#quickviewModal">-->
  <!--                      <input type="radio" name="quickViewProductSize">L-->
  <!--                    </label>-->
  <!--                    <label class="btn btn-outline-secondary" data-toggle="tooltip" title="Extra Large" data-container="#quickviewModal">-->
  <!--                      <input type="radio" name="quickViewProductSize">XL-->
  <!--                    </label>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="form-group">-->
  <!--                  <label class="font-weight-bold d-block">Color</label>-->
  <!--                  <div class="custom-color custom-color-lg">-->
  <!--                    <div class="color-item">-->
  <!--                      <input type="radio" name="colorOption" id="colorOption-1" class="custom-control-input" checked>-->
  <!--                      <label for="colorOption-1" class="rounded-circle" style="background-color:#314e76"></label>-->
  <!--                    </div>-->
  <!--                    <div class="color-item">-->
  <!--                      <input type="radio" name="colorOption" id="colorOption-2" class="custom-control-input">-->
  <!--                      <label for="colorOption-2" class="rounded-circle" style="background-color:#fcab28"></label>-->
  <!--                    </div>-->
  <!--                    <div class="color-item">-->
  <!--                      <input type="radio" name="colorOption" id="colorOption-3" class="custom-control-input">-->
  <!--                      <label for="colorOption-3" class="rounded-circle" style="background-color:#db4524"></label>-->
  <!--                    </div>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="form-row">-->
  <!--                  <div class="form-group col-6">-->
  <!--                    <label class="d-block font-weight-bold">Quantity</label>-->
  <!--                    <div class="custom-spinner">-->
  <!--                      <button class="btn btn-icon rounded-circle btn-faded-primary down" type="button"><i class="material-icons">remove</i></button>-->
  <!--                      <input type="number" class="form-control" value="1" min="1" max="999">-->
  <!--                      <button class="btn btn-icon rounded-circle btn-faded-primary up" type="button"><i class="material-icons">add</i></button>-->
  <!--                    </div>-->
  <!--                  </div>-->
  <!--                  <div class="form-group col-6 d-flex align-items-end">-->
  <!--                    <button class="btn rounded-pill btn-primary btn-block atc-demo" type="button" data-title="Hanes Men's Pullover EcoSmart Fleece Hooded Sweatshirt">Add to Cart</button>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </form>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  &lt;!&ndash; /QUICK VIEW MODAL &ndash;&gt;-->
</template>
<script>
import {mapGetters} from "vuex"
import axios from "axios";


export default {
  components: {},
  computed: {
    ...mapGetters(["getCompany"]),

    totalItem: function () {
      let sum = 0;

      this.cart.cart_items.forEach(function (item) {
        sum += (parseFloat(item.product.price) * parseFloat(item.qty));
      });
      return sum;
    },


  },
  data() {
    return {
      isModalVisible: false,
      // registration data
      fullName: '',
      email: '',
      telephone: '',
      company: '',
      password: '',
      confirm_password: '',
      categories: [],
      cart: [],
      countCart: 0,

      // registration errors
      errorName: '',
      errorEmail: '',
      errorPhone: '',
      errorPassword: '',

      //Login variables
      loginEmail: '',
      loginPassword: '',

      errorLoginEmail: '',
      errorLoginPassword: '',


      isDropdownActive: false,
      isMobileMenu: false,
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null

    }
  },
  created() {
    this.$store.dispatch("getCompany");
    this.getCategories();
    this.getCarts();
    this.numberFormat();
  },
  methods: {
    async login() {
      const formData = {

        "email": this.loginEmail,
        "password": this.loginPassword,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/login", formData);

        if (response.status == 201) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);
          const user = JSON.parse(localStorage.getItem("user"));
          this.$toast.open({
            message: "Welcome " + user.name,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
          location.reload();
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorLoginEmail = error.response.data.errors.email;
        this.errorLoginPassword = error.response.data.errors.password;

      }

    },
    async registerUser() {
      const formData = {
        "name": this.fullName,
        "email": this.email,
        "company": this.company,
        "phone": this.telephone,
        "password": this.password,
        "confirm_password": this.confirm_password,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/register", formData);

        if (response.status == 201) {
          this.$toast.open({
            message: "Congratulations, an activation mail has been sent your email " + this.email,
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })
        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.errorName = error.response.data.errors.name;
        this.errorEmail = error.response.data.errors.email;
        this.errorPhone = error.response.data.errors.phone;
        this.errorPassword = error.response.data.errors.password;
      }

    },
    async getCategories() {
      const response = await axios.get(this.$store.state.url + "/category");
      this.categories = response.data.data;
    },

    async getCarts() {
      const local_token = localStorage.getItem('token');
      const response = await axios.get(this.$store.state.url + "/cart", {
        headers: {
          'Authorization': `Bearer ${local_token}`
        }
      });
      this.cart = response.data.data;
      this.countCart = this.cart.cart_items.length;
    },

    async deleteItem(value) {
      const local_token = localStorage.getItem('token');
      const response = await axios.delete(this.$store.state.url + "/cart/" + value, {
        headers: {
          'Authorization': `Bearer ${local_token}`
        },
      });
      if (response.status == 201) {
        this.$toast.open({
          message: 'Item deleted successfully',
          type: "success",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
        this.getCarts();
      }
    },

    closeModal() {
      this.isModalVisible = false;
    },

    numberFormat(value) {
      Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },


  }
}
</script>

