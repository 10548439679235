<template>
  <!-- MENU MODAL -->
  <div class="modal fade modal-content-left" id="menuModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header align-items-center border-bottom shadow-sm z-index-1">
          <a href="#"><img :src="$store.getters.getCompany.header_logo" alt="Logo" height="40"></a>
          <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button" data-dismiss="modal">
            <i class="material-icons">close</i>
          </button>
        </div>
        <div class="modal-body px-0 scrollbar-width-thin">
          <ul class="menu" id="menu">
            <li class="no-sub"><a href="/"><i class="material-icons">home</i> Home</a></li>

            <li>
              <a href="#" class="has-arrow"><i class="material-icons">person</i> About Us</a>
              <ul>
                <li><a href="/who-we-are">Who We Are</a></li>
                <li><a href="/our-core-values">Our Core Values</a></li>
                <li><a href="/our-team">Our Team</a></li>

              </ul>
            </li>
            <li>
              <a href="#" class="has-arrow"><i class="material-icons">menu</i> Products</a>
              <ul>
                <li v-for="category in categories" :key="category.id">
                  <router-link :to="{ name: 'Category', params: { id: category.id }}">{{ category.name }}
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="no-sub"><a href="#"><i class="material-icons">rss_feed</i>
              Blog</a></li>
            <li class="no-sub"><a href="/contact"><i class="material-icons">phone</i>
              Contact Us</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /MENU MODAL -->
</template>
<script>
import axios from "axios";



export default {

  data() {
    return {
      categories: [],
      user: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
    }
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      const response = await axios.get(this.$store.state.url + "/category");
      this.categories = response.data.data;
    },
  }

}
</script>