<template>
  <div class="home">

    <div class="breadcrumb-container">
      <div class="container-fluid">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Shop</a></li>
            <li class="breadcrumb-item active" aria-current="page">Shop Grid</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="container-fluid mb-3">
      <div class="row gutters-3">
        <div class="col-md-4 col-lg-3">

          <!-- FILTER MODAL -->
          <div class="modal fade modal-content-left modal-shown-static" id="filterModal" tabindex="-1" role="dialog"
               aria-labelledby="filterModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-sm" role="document" style="max-width:300px">
              <div class="modal-content">
                <div class="modal-header border-bottom shadow-sm z-index-1">
                  <h5 class="modal-title" id="filterModalLabel">Shop Filters</h5>
                  <button class="btn btn-icon btn-sm btn-text-secondary rounded-circle" type="button"
                          data-dismiss="modal">
                    <i class="material-icons">close</i>
                  </button>
                </div>
                <div class="modal-body p-0 scrollbar-width-thin">
                  <div class="accordion accordion-caret" id="accordionSidebar">
                    <div class="card card-style1">
                      <a class="card-header h6 bg-white" data-toggle="collapse" href="#filter-categories"
                         aria-expanded="true">
                        PRODUCT CATEGORIES
                      </a>
                      <div id="filter-categories" class="collapse show">
                        <div class="card-body pt-0">

                          <ul class="list-tree">

                            <div @click="getProducts()" style="cursor: pointer" class="has-arrow">
                              <div class="dropdown-item"> All Products</div>
                            </div>

                            <div @click="getProductByCategory2(item.id)" style="cursor: pointer" class="has-arrow"
                                 v-for="(item, index) in categories" :key="index">
                              <div class="dropdown-item"> {{ item.name }}</div>
                            </div>

                          </ul>
                        </div>
                      </div>
                    </div>


                    <div class="card card-style1 border-top">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /FILTER MODAL -->
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="card card-style1">
            <div class="card-body pb-0 d-block d-md-none">
              <button type="button" data-toggle="modal" data-target="#filterModal"
                      class="btn btn-faded-primary btn-sm has-icon">
                <i class="material-icons mr-2">filter_list</i> Filter
              </button>
            </div>
            <div class="card-body d-flex align-items-center py-2">
              Sort by :
              <select class="custom-select custom-select-sm w-auto ml-1 mr-auto">
                <option>Popularity</option>
                <option>Low - High Price</option>
                <option>High - Low Price</option>
                <option>Average Rating</option>
                <option>A - Z Order</option>
                <option>Z - A Order</option>
              </select>
            </div>
          </div>
          <div
              class="d-grid grid-template-col-2 grid-template-col-lg-3 grid-template-col-xl-4 grid-gap-1 grid-gap-sm-3 mt-3">
            <div class="card card-product card-style1" v-for="product in products" :key="product.id">
              <div class="card-body">
                <button class="btn btn-icon btn-text-danger rounded-circle btn-wishlist atw-demo" data-toggle="button"
                        title="Add to wishlist" @click="saveLater(product.id)"></button>
                <button class="btn btn-icon btn-text-secondary rounded-circle btn-quickview quickview-demo"
                        type="button" title="Quick view"><i class="material-icons">zoom_in</i></button>
                <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"><img class="card-img-top"
                                                                                           :src="product.default_image.image_path"
                                                                                           alt="Card image cap">
                </router-link>
                <router-link :to="{ name: 'ProductPage', params: { id: product.id }}" class="card-title card-link">
                  {{ product.name }}
                </router-link>
                <span class="badge badge-success">Catalog #{{ product.model }}</span>
                <div class="price" v-if="product.showPrice == 1">
                  <span class="h4">{{ product.currency.symbol }}{{

                      Number(product.price).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}</span>
                </div>
                <div class="price" v-if="product.showPrice == 2">
                  <span class="h4">ON REQUEST</span>
                </div>
              </div>
              <div class="card-footer" v-if="product.showPrice == 1">
                <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"
                             class="btn btn-sm rounded-pill btn-faded-primary btn-block atc-demo">View Product
                </router-link>
              </div>
              <div class="card-footer" v-if="product.showPrice == 2">
                <router-link :to="{ name: 'ProductPage', params: { id: product.id }}"
                             class="btn btn-sm rounded-pill btn-faded-primary btn-block atc-demo">View Product
                </router-link>
              </div>
            </div>


          </div>
          <div class="card card-style1 mt-3">
            <div class="card-body">
              <ul class="pagination pagination-circle justify-content-center mb-0">
                <li class="page-item disabled"><span class="page-link has-icon"><i
                    class="material-icons">chevron_left</i></span></li>
                <li class="page-item active"><span class="page-link">1</span></li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                <li class="page-item"><a class="page-link has-icon" href="javascript:void(0)"><i class="material-icons">chevron_right</i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <OtherModal/>
  </div>

</template>

<script>

import axios from "axios";
import OtherModal from '@/components/modal.vue'


export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtherModal,

  },
  data() {
    return {
      products: [],
      categories: [],
    }
  },
  created() {
    this.$store.dispatch('getCategories');
    this.$store.dispatch('getCompany');
    this.getProducts();
    this.getCategories();
    this.getProductByCategory2();
    this.getProductByCategory();
  },
  methods: {
    async getProductByCategory() {
      const response = await axios.get(this.$store.state.url + "/category/" + this.$route.params.id);
      this.products = response.data.data;
    },
    async getProductByCategory2(value) {
      const response = await axios.get(this.$store.state.url + "/category/" + value);
      this.products = response.data.data;
    },
    async getProducts() {
      const response = await axios.get(this.$store.state.url + "/products");
      this.products = response.data.data;
    },
    async getCategories() {
      const response = await axios.get(this.$store.state.url + "/category");
      this.categories = response.data.data;
    },

    async saveLater(value) {
      const local_token = localStorage.getItem('token');
      const formData = {
        "product": value,
      }
      try {
        const response = await axios.post(this.$store.state.url + "/wishlist", formData, {
          headers: {
            'Authorization': `Bearer ${local_token}`
          }
        });
        if (response.status == 201) {
          this.$toast.open({
            message: 'Item added to Wish list successfully',
            type: "success",
            duration: 5000,
            dismissible: true,
            position: 'top-right'
          })

        }
      } catch (error) {
        this.$toast.open({
          message: error.response.data.message,
          type: "error",
          duration: 5000,
          dismissible: true,
          position: 'top-right'
        })
      }

    },
  }

}

</script>


